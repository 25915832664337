const auth = (to, from, next) => {
    let token = sessionStorage.getItem('token')

    if(to.matched.some(record => record.meta.auth)) {
      if (token == null) {
        return next({ name: 'Login'})
      }
    }

    if(!to.matched.some(record => record.meta.auth)) {
      if (token !== null) {
        return next({ name: 'Dashboard' })
      }
    }

    return next()
};

export default auth

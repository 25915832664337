<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <ul class="nav">
            <li class="nav-item">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <a href="https://accounts.binance.com/register?ref=482479004" target="black" class="text-nowrap ml-4 mt-4 text-info m-4" style="font-size: 0.9em; text-transform: uppercase;">
                        {{ $t("login.createBinanceAccount", { exchange: "Binance" }) }}
                      </a>
                    </div>
                    <div class="col-md-12">
                      <a href="https://www.kucoin.com/r/rf/QBS4KLEF" target="black" class="text-nowrap ml-4 mt-4 text-info m-4" style="font-size: 0.9em; text-transform: uppercase;">
                        {{ $t("login.createBinanceAccount", { exchange: "Kucoin" }) }}
                      </a>
                    </div>
                    <div class="col-md-12">
                      <a href="https://www.okex.com/pt-br/join/2837578" target="black" class="text-nowrap ml-4 mt-4 text-info m-4" style="font-size: 0.9em; text-transform: uppercase;">
                        {{ $t("login.createBinanceAccount", { exchange: "Okex" }) }}
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row mt-3">
                    <div class="col-md-12">
                      <a href="https://t.me/neionetwork" target="_blank" rel="noopener" class="text-nowrap ml-4 mt-4 text-info m-4" style="font-size: 0.9em; text-transform: uppercase;">
                        <b>{{ $t("telegram") }}</b>
                      </a>
                    </div>
                    <div class="col-md-12">
                      <a href="https://www.instagram.com/neio_network/" target="_blank" rel="noopener" class="text-nowrap ml-4 mt-4 text-info m-4" style="font-size: 0.9em; text-transform: uppercase;">
                        <b>{{ $t("instagram") }}</b>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-12">
          <table class="table">
            <tbody>
              <tr>
                <td class="text-nowrap" style="font-size: 0.9em;font-weight: 500;text-align: left;text-transform: uppercase;">
                  {{ $t("Number of active users on the platform") }}: <strong> {{ formatNumber(users) }} </strong>
                </td>
              </tr>
              <tr>
                <td class="text-nowrap" style="font-size: 0.9em;font-weight: 500;text-align: left;text-transform: uppercase;">
                  {{ $t("Number of orders issued") }}: <strong> {{ formatNumber(operations) }} </strong> <span style="font-size: 0.7em;"> {{ $t("in the last 30 days") }} </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="copyright mt-4">
            <i class="tim-icons icon-wifi wifi-big mr-2" :class='{ "wifi-color-green": ws.connected, "wifi-color-red": !ws.connected }'> </i>  NeiO network
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import axios from '@/services/config'

export default {
  props: ['ws'],
  components: {
  },

  data() {
    return {
      year: new Date().getFullYear(),
      users: 0,
      operations: 0,
      interval: 0
    }
  },

  methods: {
    updateUsers() {
      axios.get('https://api.neio.network/v7/general-users-data').then((response) => {
        this.users = response.data.result
      })
    },

    updateOperations() {
      axios.get('https://api.neio.network/v7/general-operations-data').then((response) => {
        this.operations = response.data.result
      })
    },

    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

  },

  created() {
    this.updateUsers()
    this.updateOperations()

    this.interval = setInterval(() => {
      this.updateUsers()
      this.updateOperations()
    }, 1000 * 60 * 60)
  },

  destroyed() {
    clearInterval(this.interval)
  }
};
</script>
<style scoped>
.wifi-big {
  font-size: 1.3em;
  font-weight: 900;
}

.wifi-color-green {
  color: #04B404;
}

.wifi-color-red {
  color: #FF0040;
}

</style>

export default {
  namespaced: true,
  state: {
    rows: []
  },
  actions: {
    setSymbols({ commit }, symbols) {
      commit('SET_SYMBOLS', symbols );
    }
  },
  mutations: {
    SET_SYMBOLS( state, symbols ) {
        state.rows = symbols;
    },
  },
  getters: {
    symbols( state ) {
        return state.rows;
    },
    symbolByGroup: (state, getters) => (g, type) => {
      const row = state.rows.find(s => (s.group.toUpperCase() === g.toUpperCase() && s.currency.toUpperCase() === type.toUpperCase()));
      if (row === undefined) return {}

      return row;
    }
  }
};

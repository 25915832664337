import axios from '@/services/config'
import { URI_V3 } from '@/services/config'

export const companyUsers = (params = {}) => {
  return axios.get(`${URI_V3}/company/users`, { params });
}

export const companyNewUser = (data) => {
  return axios.post(`${URI_V3}/company/users`, data);
}

export const companyUpdateUser = (id, data) => {
  return axios.put(`${URI_V3}/company/users/${id}`, data);
}

export const listOrderCompany = (params = {}) => {
  return axios.get(URI_V3 + `/company/orders`,  { params });
}

export const newOrderCompany = (companyUserId, data) => {
  return axios.post(URI_V3 + `/company/users/${companyUserId}/orders`, data);
}

export const updateOrderCompany = (companyUserId, companyOrderId, data) => {
  return axios.put(URI_V3 + `/company/users/${companyUserId}/orders/${companyOrderId}`, data);
}

export const listCloseOperationsCompany = (userId, params = {}) => {
  return axios.get(URI_V3 + `/company/users/${userId}/operations/closed`, { params });
}

export const listEventCompany = (userId, data) => {
  return axios.post(URI_V3 + `/company/users/${userId}/event`, data);
}

export const listFinancialCompany = (params = {}) => {
  return axios.get(URI_V3 + `/company/bank-statement-business`, { params });
}

export const accountStatusCompany = (data) => {
  return axios.post(URI_V3 + `/company/account-status`, data);
}

export const getAccountStatusCompany = (params = {}) => {
  return axios.get(URI_V3 + `/company/account-status/view`, { params });
}

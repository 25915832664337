export default {
  namespaced: true,
  state: {
    user: null,
    users: [],
    orders: [],
    synchronize: false
  },
  actions: {
    setUser({ commit }, value) {
      commit('SET_USER', value );
    },

    setUsers({ commit }, value) {
      commit('SET_USERS', value );
    },

    setOrders({ commit }, value) {
      commit('SET_ORDERS', value );
    },

    setSynchronize({ commit }, value) {
      commit('SET_SYNCHRONIZE', value );
    },
  },
  mutations: {
    SET_USERS( state, value ) {
      state.users = value;
    },

    SET_USER( state, value ) {
        state.user = value.id;
    },

    SET_ORDERS( state, value ) {
      state.orders = value;
    },

    SET_SYNCHRONIZE( state, value ) {
      const key = state.users.findIndex(u => u.id === state.user);
      if (key === -1) return
      return state.users[key].synchronize = value
    },
  },
  getters: {
    users( state ) {
        return state.users;
    },

    user( state ) {
        const key = state.users.findIndex(u => u.id === state.user);
        return state.users[key]
    },

    orders( state ) {
        return state.orders;
    },

    synchronize ( state ) {
      const key = state.users.findIndex(u => u.id === state.user);
      if (key === -1) return
      return state.users[key].synchronize
    },

    // valueInPurchases
    valueInPurchases ( state ) {
      const key = state.users.findIndex(u => u.id === state.user);
      if (key === -1) return 0

      const valueInPurchases = state.users[key].orders.filter(o => o.price > 0)
        .map(o => o.operations.map(p => p.amount_buy * p.buy).reduce((accumulator, currentValue) => accumulator + currentValue, 0))
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

      return parseFloat((valueInPurchases).toFixed(2))
    },

    // deficit
    deficit ( state ) {
      const key = state.users.findIndex(u => u.id === state.user);
      if (key === -1) return 0

      const valueInPurchases = state.users[key].orders.filter(o => o.price > 0)
        .map(o => o.operations.map(p => p.amount_buy * p.buy).reduce((accumulator, currentValue) => accumulator + currentValue, 0))
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

      const nowValueInPurchases = state.users[key].orders.filter(o => o.price > 0)
        .map(o => o.operations.map(p => p.amount * o.price).reduce((accumulator, currentValue) => accumulator + currentValue, 0))
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

      return parseFloat((valueInPurchases - nowValueInPurchases).toFixed(2))
    },

    // sumProfit
    sumProfit ( state ) {
      const key = state.users.findIndex(u => u.id === state.user);
      if (key === -1) return 0

      const value = state.users[key].orders.filter(o => o.price > 0)
        .map(o => o.gainUsd)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

      return parseFloat((value).toFixed(2))
    },

    // sumSymbolsOnHold
    sumSymbolsOnHold ( state ) {
      const key = state.users.findIndex(u => u.id === state.user);
      if (key === -1) return 0

      const value = state.users[key].orders.filter(o => o.price > 0)
        .map(o => o.symbolOnHoldAverageValueNow)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

      return parseFloat((value).toFixed(2))
    },

    // sumOfConfirmedAmountOnHold
    sumOfConfirmedAmountOnHold ( state ) {
      const key = state.users.findIndex(u => u.id === state.user);
      if (key === -1) return 0

      return 0
    },

    // riskRate
    riskRate: (state) => (ext = 0) => {
      const key = state.users.findIndex(u => u.id === state.user);
      if (key === -1) return 0

      const { husdHuobi = 0, usdtHuobi = 0, usdtKucoin = 0, usdtBinance = 0, busdBinance = 0, usdtOkex = 0 } = state.users[key]
      // usd available
      let usdAvailable = husdHuobi + usdtHuobi + usdtKucoin + usdtBinance + busdBinance + usdtOkex

      // next purchases
      let nextPurchases = 0;

      state.users[key].orders.filter(o => o.buy_lock === false).forEach(o => {
          const value = (o.operations.length < o.max) ? ((o.max - o.operations.length) * o.value) : 0
          nextPurchases = nextPurchases + value
        })

      // value in purchases
      const valueInPurchases = state.users[key].orders.filter(o => o.price > 0)
        .map(o => o.operations.map(p => p.amount * p.buy).reduce((accumulator, currentValue) => accumulator + currentValue, 0))
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

      return parseFloat((((((valueInPurchases || 0) + ext + (nextPurchases || 0)) / ((valueInPurchases || 0) + usdAvailable)) * 100) || 0).toFixed(2))
    },

    // capitalAtRisk
    capitalAtRisk(state) {
      const key = state.users.findIndex(u => u.id === state.user);
      if (key === -1) return 0

      const value = state.users[key].orders.map(o => {
        const valueInBuy = o.operations.map(p => p.amount_buy * p.buy).reduce((a, b)=> a + b, 0)
        let toOpen = 0
        if (o.buy_lock === true)
          toOpen = 0
        else
          toOpen = (o.operations.length < o.max) ? (o.max - o.operations.length) : 0;

        return (valueInBuy + (toOpen * o.value))
      }).reduce((a, b)=> a + b, 0)

      return parseFloat(value.toFixed(2))
    }

  }
};

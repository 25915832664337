export default {
  namespaced: true,
  state: {
    financial: {
      situation: { usd: 0, type: "c" }
    },
    profile: {
      name: '',
      requestWithdrawal: false
    }
  },

  actions: {
    setSituation({ commit }, situation) {
      commit('SET_SITUATION', situation );
    },

    setProfile({ commit }, profile) {
      commit('SET_PROFILE', profile );
    }
  },

  mutations: {
    SET_SITUATION( state, situation ) {
      state.financial.situation = situation;
    },

    SET_PROFILE( state, profile ) {
      state.profile = { ...state.profile, ...profile };
    },
  },
  getters: {
    situation( state ) {
        return state.financial.situation;
    },

    profile( state ) {
      return state.profile;
    },
  }
};

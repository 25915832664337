<template>
    <div class="col-md-12">
        <BaseAlert type="primary" :key="broker.name" v-for="broker of allowedBrokers">
          {{ $t('apiAlert', { exchange: broker.name, message: status[broker.name].error }) }} <br />
          <router-link  :to="{ name: 'FrequentlyAskedQuestions' }" class="breadcrumb-link" v-if="broker.name.toLowerCase() === 'binance'">
            <base-button size="sm" type="warning"> {{ $t("seeHowToSolve") }} </base-button>
          </router-link>
          <base-button size="sm ml-2" type="success"
            :disabled='checkAgainIfErrorPersistsNoRequest[broker.name.toLowerCase()] || false'
            @click="checkAgainIfErrorPersists(broker.name)">
              {{ $t("Check again if error persists") }}
          </base-button>
        </BaseAlert>
      </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { BaseAlert, Badge } from '@/components'
import { listBrokers, verifyAccount } from '../services'

export default {
  name: 'balance',
  props: ["ws"],
  components: {
    BaseAlert,
    [Badge.name]: Badge
  },
  data() {
    return {
      checkAgainIfErrorPersistsNoRequest: { binance: false, kucoin: false, okex: false, huobi: false }
    }
  },
  methods: {
    ...mapActions({
      setBrokers: "exchange/setBrokers",
      setStatus: "exchange/setStatus",
    }),

    hasError(exchange) { // remove one !
      return !!this.status[exchange.toLowerCase()].error
    },

    async listBrokers() {
      try {
        const data = await listBrokers()
        this.setBrokers(data.listBrokers)
      } catch (e) {
        console.info(e)
      }
    },

    async verifyAccountAll() {
      const keyName = 'verifyAccount:time'
      const time = localStorage.getItem(keyName)

      if (time === null || parseInt(time) < (new Date()).getTime()) {
        const nowTime = new Date()
        nowTime.setMinutes((new Date()).getMinutes() + 25)
        localStorage.setItem(keyName, nowTime.getTime())

        this.allowedBrokersVerify.forEach(broker => {
          verifyAccount(broker.name.toLowerCase())
            .then(({ errors = [] }) => {
              const key = broker.name.toLowerCase()
              let value = { error: '' }

              if (errors.length > 0) {
                value.error = errors[0].message
                this.setStatus({ key, value })
              } else {
                this.setStatus({ key, value })
              }
            }).catch(({ response }) => {
              console.info(response.data.errors[0].message)
            })
        })
      }
    },

    async checkAgainIfErrorPersists(exchange) {
      const key = exchange.toLowerCase()

      this.checkAgainIfErrorPersistsNoRequest[key] = true
      setTimeout(() => this.checkAgainIfErrorPersistsNoRequest[key] = false, 1000 * 10)

      try {
        let value = { error: '' }
        const { errors = [] } =  await verifyAccount(key)

        if (errors.length > 0) {
          value.error = errors[0].message
          this.setStatus({ key, value })
          this.$notify({ type: 'danger', message: value.error , verticalAlign: 'top', horizontalAlign: 'center', timeout: 30000 })
        } else {
          this.setStatus({ key, value })
          this.$notify({ type: 'success', message: this.$t("apiOk", { exchange: key }) , verticalAlign: 'top', horizontalAlign: 'center', timeout: 25000 })
        }

      } catch ({ response }) {
        console.info(response.data.errors[0].message)
      }
    }

  },
  async created() {
    await this.listBrokers()
    setTimeout(() => this.verifyAccountAll(), 5000)
  },
  computed: {
    ...mapGetters({
      brokers: "exchange/brokers",
      status: "exchange/status",
      operations: 'user/exchangeOperations',
    }),

    allowedBrokers() {
      return this.brokers
        .filter(broker => broker.blocked === false)
        .filter(broker => broker.active === true)
        .filter(broker => this.operations(broker.name).length > 0)
        .filter(broker => this.hasError(broker.name))
    },

    allowedBrokersVerify() {
      return this.brokers
        .filter(broker => broker.blocked === false)
        .filter(broker => broker.active === true)
        .filter(broker => this.operations(broker.name).length > 0)
    },
  }
};
</script>
<style scoped>
  .min-d {
    min-width: 100px;
  }
</style>

import axios from 'axios'

const URI_ROMA = 'https://neio-graphql-prod-4z6n3mffoq-rj.a.run.app';
const URI_BASE = 'https://api.neio.network/v1';
const URI_BASE_V2 = 'https://api.neio.network/v2';
const URI_V3 = 'https://api.neio.network/v3';
const WSS = 'https://wss.neio.network';

export { URI_ROMA, URI_BASE, URI_BASE_V2, WSS, URI_V3 };

const removeToken = () => {
  if (sessionStorage.getItem('token')) {
    sessionStorage.removeItem('token');
    window.location.href = '/login';
  }
}

axios.interceptors.request.use(function (config) {

    let token = sessionStorage.getItem('token');
    let lang = localStorage.getItem('valueLang', lang)

    if (token != null) config.headers['Authorization'] = `Bearer ${token}`;

    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  // Add a response interceptor
  axios.interceptors.response.use(function (response) {

    const { data } = response
    if (data && data.hasOwnProperty("errors")) {
      const { statusCode } = data["errors"][0]
      if ([403, 401].includes(statusCode)) {
        removeToken()
      }
    }

    return response;
  }, function (error) {
    const { status } = error.response;
    if ([403, 401].includes(status))  {
      removeToken()
    }

    return Promise.reject(error);
  });

  export default axios;

import Vue from 'vue';
import Vuex from 'vuex';

import user from './user';
import userState from './user-state';
import symbols from './symbols';
import company from './company';
import exchange from './exchange';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    userState,
    symbols,
    company,
    exchange
  },
});

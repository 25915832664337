export default {
  namespaced: true,
  state: {
    brokers: [],
    status: {
      binance: { error: '' },
      kucoin: { error: '' },
      okex: { error: '' },
      huobi: { error: '' }
    }
  },
  actions: {
    setBrokers({ commit }, value) {
      commit('SET_BROKERS', value )
    },
    setStatus({ commit }, data) {
      commit('SET_STATUS', data )
    },
  },
  mutations: {
    SET_BROKERS( state, value ) {
      state.brokers = value
    },
    SET_STATUS( state, data ) {
      const { key, value } = data
      state.status[key] = value
    },
  },
  getters: {
    brokers( state ) {
      return state.brokers
    },
    status( state ) {
      return state.status
    }
  }
}

import moment from 'moment';

const allowUSD = (currency) => ["USDT", 'BUSD', 'USDC', 'HUSD', 'USD'].includes(currency.toUpperCase())
const showAction = (exchange, value) => {
  // if (exchange.toUpperCase() === "BINANCE" && value < 15) return false
  // if (exchange.toUpperCase() === "OKEX" && value < 10) return false
  // if (exchange.toUpperCase() === "KUCOIN" && value < 7) return false
  // if (exchange.toUpperCase() === "HUOBI" && value < 7) return false
  return true
}

export const setValueWallet = (currentWallet, wallet, orders) => {
  const newWallet =  wallet.map(w => {
    const o = orders.filter(o => o.group.toUpperCase() === w.symbol.toUpperCase())
    const filteredPrice = o.filter(fo => allowUSD(fo.currency) && fo.price > 0)

    const price = filteredPrice.length > 0 ? filteredPrice[0].price : 0
    const exchange = filteredPrice.length > 0 ? filteredPrice[0].exchange : ''
    let orderId = filteredPrice.length > 0 ? filteredPrice[0].id : ''
    const currentUsd = filteredPrice.length > 0 ? filteredPrice[0].currency : ''

    if (filteredPrice.length > 1 && currentUsd.toUpperCase() === "HUSD") {
      orderId = filteredPrice.filter(o => o.currency.toUpperCase() !== "HUSD")[0].id
    }

    const amountOnOrderInNeio = o.reduce((t, od) => t + od.operations.reduce((b, { amount }) => b + amount, 0), 0)

    w.inOrders = o
    w.exchange = exchange
    w.orderId = orderId
    w.symbol = w.symbol.toUpperCase()
    w.amountAtExchange = parseFloat(w.free.toFixed(8))
    w.amountAtExchangeView = price > 0 ? parseFloat((w.free * price).toFixed(2)) : 0
    w.amountOnOrderInNeio = parseFloat(amountOnOrderInNeio.toFixed(8))
    w.amountOnOrderInNeioView = price > 0 ? parseFloat((amountOnOrderInNeio * price).toFixed(2)) : 0
    w.difference = parseFloat((w.amountAtExchangeView - w.amountOnOrderInNeioView).toFixed(2))
    w.selected = false
    return w
  }).filter(w => w.inOrders.length > 0).filter(w => w.free > 0)

  newWallet.forEach(nw => {
    if (currentWallet.map(a => a.symbol).includes(nw.symbol)) {
      const index = currentWallet.findIndex(c => c.symbol = nw.symbol)
      nw.selected = currentWallet[index].selected
    }
  })


  return newWallet
}

// operations
export const setOperations = (orders, oldOperations) => {
  let operations = []
  orders.filter(o => o.symbol).forEach(o => {
      o.operations.forEach(op => {
        let request = false
        const oldOperation =  oldOperations.find(oo => oo.id === op.id)

        if (oldOperation) {
          request = oldOperation.request
        }
        const exchange = (o.exchange || "binance").toUpperCase()
        let action = parseFloat(((o.price * op.amount) - (op.buy * op.amount_buy)).toFixed(2)) >= 0.07

        if (exchange === 'BINANCE')
          action =  parseFloat(((o.price * op.amount) - (op.buy * op.amount_buy)).toFixed(2)) >= 0.07

        if (exchange === 'HUOBI')
          action =  parseFloat(((o.price * op.amount) - (op.buy * op.amount_buy)).toFixed(2)) >= 0.07

        if (exchange === 'KUCOIN')
          action =  parseFloat(((o.price * op.amount) - (op.buy * op.amount_buy)).toFixed(2)) >= 0.07


        const operation = {
          ...op,
          orderId: o.id,
          currency: o.currency,
          countError: o.countError,
          messageError: o.messageError,
          exchange: exchange,
          request,
          buy: parseFloat(op.buy.toFixed(8)),
          price: o.price,
          symbol: o.symbol.toUpperCase(),
          invested: parseFloat((op.amount_buy * op.buy).toFixed(2)),
          currentValue: parseFloat((op.amount * o.price).toFixed(2)),
          situation: parseFloat(((((o.price * op.amount) / (op.buy * op.amount_buy)) * 100) - 100).toFixed(2)),
          action,
          createdAt: op.createdAt,
          viewSituation: `${((o.price * op.amount) - (op.buy * op.amount_buy)).toFixed(2)} (${parseFloat(((((o.price * op.amount) / (op.buy * op.amount_buy)) * 100) - 100).toFixed(2))}%)`
        }
        operations.push(operation)
      })

      return operations
  });

  return operations
}

// orders
export const setOrders = (orders, old, wallet, localSymbols, holdStore = []) => {

  const adapt = orders.filter(o => o.symbol).map(o => {

    const findSymbol = localSymbols.find(ls => ls.id === o.symbol_id)

    if (findSymbol !== undefined && o.symbol === "") {
      const { currency, group, bid, exchange } = findSymbol
      o.group = group
      o.currency = currency
      o.exchange = exchange
      o.symbol = `${group.toUpperCase()}${currency.toUpperCase()}`
      o.price = bid
    }

    const symbolWithExchange = `${(o.exchange || '').toUpperCase()}-${(o.symbol || '').toUpperCase()}`

    const opens = o.operations.length
    let opip = o.opip || 0
    let coip = o.coip || 0
    const status = statusFn(o)
    const entry = entryFn(o)
    let inWallet = o.operations.map(p => p.amount).reduce((a, b)=> a + b, 0)
    inWallet = parseFloat(inWallet.toFixed(8))

    let free = 0;

    const wll = wallet.filter(w => w.symbol.toLowerCase() === o.group.toLowerCase()).map(w => w.free)
    if (wll.length) free = wll.reduce((a, b)=> a + b, 0)

    free = parseFloat(free.toFixed(8))
    const inDifference = parseFloat((free - inWallet).toFixed(8))

    const deficit = parseFloat((o.operations.map(p => ((p.amount *  o.price) - (p.amount_buy *  p.buy))).reduce((a, b)=> a + b, 0)).toFixed(2))
    const averagePrice = parseFloat(((o.operations.map(p => p.amount_buy *  p.buy).reduce((a, b) => a + b, 0)) / o.operations.map(p => p.amount_buy).reduce((a, b) => a + b, 0)).toFixed(8))

    const perfilRender = perfilFn(o.perfil)
    const hold = { class: o.profit_save > 0 ? "text-success" : "text-warning", label: o.profit_save > 0 ? "on" : "off", value: 0.00 }
    const holdOn = o.profit_save > 0 ? 1: 0
    let profitValue = 0;

    const itemHold = holdStore.find(hs => hs.orderId === o.id)
    if (itemHold !== undefined) {
      hold.value = parseFloat((itemHold.symbolOnHold * o.price).toFixed(2))
      profitValue = parseFloat((itemHold.gain).toFixed(2))
    }


    return { ...o, opens, status, entry, opip, coip, perfilRender, hold, wallet: free, inWallet, inDifference, group: o.group.toUpperCase(), deficit, averagePrice, symbolWithExchange, holdOn, profitValue  }
  })


  adapt.forEach(a => {
      const find = old.find(o => o.id === a.id)

      if (find) {
        find.opens = a.opens
        find.status = a.status
        find.entry = a.entry
        find.perfilRender = a.perfilRender
        find.buy_lock = a.buy_lock
        find.buyIn = a.buyIn
        find.rsi = a.rsi
        find.max = a.max
        find.price = a.price
        find.buyStop = a.buyStop
        find.buy_back = a.buy_back
        find.countError = a.countError
        find.currency = a.currency
        find.messageError = a.messageError
        find.operations = a.operations
        find.perfil = a.perfil
        find.profit = a.profit
        find.profit_save = a.profit_save
        find.sellIn = a.sellIn
        find.sellStop = a.sellStop
        find.show = a.show
        find.situation = a.situation
        find.symbol = a.symbol
        find.exchange = a.exchange
        find.symbol_id = a.symbol_id
        find.value = a.value
        find.value_max = a.value_max
        find.value_min = a.value_min
        find.hold = a.hold
        find.wallet = a.wallet
        find.inWallet = a.inWallet
        find.inDifference = a.inDifference
        find.averagePrice = a.averagePrice
        find.deficit = a.deficit
        find.coip = a.coip
        find.opip = a.opip
        find.symbolWithExchange = a.symbolWithExchange
        find.holdOn = a.holdOn
        find.activeAnalysis = a.activeAnalysis
        find.profitValue = a.profitValue
      } else
        old.push({ ...a, request: false, selected: false, sellRequest: false })
  })

  return old
}


// riskRate
export const setRiskRate = (orders, currency, ext = 0, cur = 'usd') => {
  let filterCurrency = ['usdt', 'busd', 'usdc', 'dai', 'tusd', 'pax', 'usd', 'husd'];
  if (cur === 'brl') {
    filterCurrency = ['brl']
  }

  const { usdt = 0, busd = 0, usdc = 0, dai = 0, tusd = 0, pax = 0, brl = 0, usd = 0, husd = 0, } = currency
  // usd available
  let usdAvailable = usdt + busd + usdc + dai + pax + tusd + usd + husd

  if (cur === 'brl') {
    usdAvailable = brl
  }

  const filteredOrders = orders.filter(o => filterCurrency.includes(o.currency))
  // next purchases
  let nextPurchases = 0;

    filteredOrders.filter(o => o.buy_lock === false).forEach(o => {
      const value = (o.operations.length < o.max) ? ((o.max - o.operations.length) * o.value) : 0
      nextPurchases = nextPurchases + value
    })

  // value in purchases
  const valueInPurchases = filteredOrders.filter(o => o.price > 0)
    .map(o => o.operations.map(p => p.amount * p.buy).reduce((accumulator, currentValue) => accumulator + currentValue, 0))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  return parseFloat((((((valueInPurchases || 0) + ext + (nextPurchases || 0)) / ((valueInPurchases || 0) + usdAvailable)) * 100) || 0).toFixed(2))
}

export const setDeficit = (orders, cur) => {
  let filterCurrency = ['usdt', 'busd', 'usdc', 'dai', 'tusd', 'pax', 'usd', 'husd'];
  if (cur === 'brl') {
    filterCurrency = ['brl']
  }

  const filteredOrders = orders.filter(o => filterCurrency.includes(o.currency))

  const valueInPurchases = filteredOrders.filter(o => o.price > 0)
    .map(o => o.operations.map(p => p.amount_buy * p.buy).reduce((accumulator, currentValue) => accumulator + currentValue, 0))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  const nowValueInPurchases = filteredOrders.filter(o => o.price > 0)
    .map(o => o.operations.map(p => p.amount * o.price).reduce((accumulator, currentValue) => accumulator + currentValue, 0))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  return parseFloat((valueInPurchases - nowValueInPurchases).toFixed(2))
}


export const setCloseOperations = (o, format = null) => {
  o.exchange = o.exchange.toUpperCase()
  o.par = `${o.coin.toUpperCase()}${o.usd.toUpperCase()}`
  o.purchasePrice = o.buy
  o.salePrice = o.sell
  o.salesFee = `${o.commission_sell_type ? `${o.commission_sell} ${o.commission_sell_type}` : ``}`
  o.purchaseFee = `${o.commission_buy_type ? `${o.commission_buy} ${o.commission_buy_type}` : ``}`
  o.paid = parseFloat(o.buy_total.toFixed(2))
  o.sold = parseFloat(o.sell_total.toFixed(2))
  o.profit = o.profit
  o.altcoinSaved = `${o.profit_coin} ${o.coin.toUpperCase()}`
  o.boughtIn = moment(o.created_at, "DD-MM-YYYY hh:mm").format(format)
  o.soldIn = moment(o.updated_at, "DD-MM-YYYY hh:mm").format(format)

  return o;
}


export const groupByTutorial = (args) => {
  let newArgs = [];

  args.forEach((arg) => {
      const newArg = newArgs.find(a => a.group === arg.group)
      if (newArg !== undefined) {
          newArg.rows.push(arg)
      } else {
          newArgs.push({
              group: arg.group,
              title: arg.group,
              key: arg.id,
              rows: [arg]
          })
      }
  })

  return newArgs;
}


export const setValueInPurchases = (orders, cur = "usd") => {
  let filterCurrency = ['usdt', 'busd', 'usdc', 'dai', 'tusd', 'pax', 'usd', 'husd'];
  if (cur === 'brl') {
    filterCurrency = ['brl']
  }

  const filteredOrders = orders.filter(o => filterCurrency.includes(o.currency))

  const valueInPurchases = filteredOrders.filter(o => o.price > 0)
    .map(o => o.operations.map(p => p.amount_buy * p.buy).reduce((accumulator, currentValue) => accumulator + currentValue, 0))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  return parseFloat((valueInPurchases).toFixed(2))
}


const statusFn = (o) => {
  if (o.buy_lock === true && o.situation != 3)
    return { value : -777, label: 'manualLock', class: "text-warning" }

    if (o.coip > 0 && o.situation != 3)
    return { value : -778, label: 'confirmingSale', class: "text-warning" }

  if (o.opip > 0 && o.situation != 3)
    return { value : -778, label: 'confirmingPurchase', class: "text-warning" }

  if (o.max < o.operations.length && o.situation != 3)
    return { value : 774, label: 'openTradesRule', class: "text-warning" }

  switch (o.situation) {
      case 3:
        return { value : -977, label: 'errorBlocking', class: "text-danger" }
      case 4:
        return { value : -775, label: 'APILockNotConfigured', class: "text-danger" }
      case 5:
        if (o.max < o.operations.length) {
          return { value : 101, label: 'analyzingTheMarket', class: "text-success" }
        } else {
          return { value : 774, label: 'openTradesRule', class: "text-warning" }
        }
      case 6:
        if (o.buy_lock) {
          return { value : -773, label: 'manualLock', class: "text-danger" }
        }
        return { value : 101, label: 'analyzingTheMarket', class: "text-success" }
      case 8:
        return { value : 301, label: 'insufficientFunds', class: "text-warning" }
      case 9:
        return { value : 201, label: 'lockPriceRange', class: "text-danger" }
      case 1:
        return { value : 101, label: 'analyzingTheMarket', class: "text-success" }
      default:
        return { value : 100, label: 'waitingForOpportunity', class: "text-success" }
  }
}

const entryFn = (o) => {
  return (o.buyIn && o.price)
    ? parseFloat((((o.price / o.buyIn) - 1) * 100).toFixed(2))
    : 0;
}

const perfilFn = (p) => {
  switch (parseInt(p)) {
      case 5:
      return { label: "ultraConservative", value: 5 }
      case 1:
      return { label: "conservative", value: 4 }
      case 3:
      return { label: "moderate", value: 3 }
      case 2:
      return { label: "aggressive", value: 2 }
      case 4:
      return { label: "ultraAggressive", value: 1 }
    }
}

export const setRiskRateUsd = (orders, cur = 'usd') => {

  let filterCurrency = ['usdt', 'busd', 'usdc', 'dai', 'tusd', 'pax', 'usd', 'husd'];
  if (cur === 'brl') {
    filterCurrency = ['brl']
  }

  const filteredOrders = orders.filter(o => filterCurrency.includes(o.currency))

  const value = filteredOrders.map(o => {
    const valueInBuy = o.operations.map(p => p.amount_buy * p.buy).reduce((a, b)=> a + b, 0)
    let toOpen = 0
    if (o.buy_lock === true)
      toOpen = 0
    else
      toOpen = (o.operations.length < o.max) ? (o.max - o.operations.length) : 0;

    return (valueInBuy + (toOpen * o.value))
  }).reduce((a, b)=> a + b, 0)

  return parseFloat(value.toFixed(2))
}














// statusFn company
const companyStatusFn = (o) => {
  if (o.l === true && o.m != 3)
    return { value : -777, label: 'manualLock', class: "text-warning" }

  switch (o.m) {
      case 3:
        return { value : -977, label: 'errorBlocking', class: "text-danger" }
      case 4:
        return { value : -775, label: 'APILockNotConfigured', class: "text-danger" }
      case 5:
        return { value : 774, label: 'openTradesRule', class: "text-warning" }
      case 6:
        return { value : -773, label: 'manualLock', class: "text-danger" }
      case 8:
        return { value : 301, label: 'insufficientFunds', class: "text-warning" }
      case 9:
        return { value : 201, label: 'lockPriceRange', class: "text-danger" }
      case 1:
        return { value : 101, label: 'analyzingTheMarket', class: "text-success" }
      case 37:
        return { value : -976, label: 'verifySuspendedAccount', class: "text-danger" }
      default:
        return { value : 100, label: 'waitingForOpportunity', class: "text-success" }
  }
}

//  entryFn company
const companyEntryFn = (o) => {
  return (o.g && o.p)
    ? parseFloat((((o.p / o.g) - 1) * 100).toFixed(2))
    : 0;
}











// set company operations
export const setCompanyOperations = (orders, oldOperations) => {
  let operations = []
  orders.filter(o => o.k)
    .forEach(o => {
      o.op.forEach(p => {
        let request = false
        const oldOperation =  oldOperations.find(oo => oo.id === p.u)

        if (oldOperation)
          request = oldOperation.request

        const exchange = o.k.toUpperCase()
        let action = parseFloat(((o.p * p.a) - (p.b * p.q)).toFixed(2)) >= 0.10

        if (exchange === 'BINANCE')
          action =  parseFloat(((o.p * p.a) - (p.b * p.q)).toFixed(2)) >= 0.10

        if (exchange === 'HUOBI')
          action =  parseFloat(((o.p * p.a) - (p.b * p.q)).toFixed(2)) >= 0.10

        if (exchange === 'KUCOIN')
          action =  parseFloat(((o.p * p.a) - (p.b * p.q)).toFixed(2)) >= 0.10

        const operation = {
          id: p.u,
          amount: p.a,
          amount_buy: p.q,
          buy: p.b,
          commission_buy_type: p.t,
          commission_buy: p.w,
          commission_sell: p.s,
          createdAt: p.c,
          orderId: o.u,
          company_order_id: o.u,
          canBuy: o.n,
          symbol_id: o.x,
          profit: o.q,
          profit_save: o.c,
          profile: o.v,
          situation: o.m,
          buy_lock: o.l,
          wallet: o.w,
          buy_back: o.a,
          max: o.d,
          show: o.o,
          group: o.r,
          currency: o.t,
          value: o.y,
          value_min: o.i,
          value_max: o.f,
          BUYIN: o.g,
          BUYSTOP: o.h,
          SELLIN: o.j,
          SELLSTOP: o.z,
          countError: o.b,
          messageError: o.bm,
          exchange: exchange,
          request,
          buy: parseFloat(p.b.toFixed(8)),
          price: o.p,
          symbol: o.s.toUpperCase(),
          invested: parseFloat((p.q * p.b).toFixed(2)),
          currentValue: parseFloat((p.a * o.p).toFixed(2)),
          situation: parseFloat(((((o.p * p.a) / (p.b * p.q)) * 100) - 100).toFixed(2)),
          action,
          createdAt: p.c,
          viewSituation: `${((o.p * p.a) - (p.b * p.q)).toFixed(2)} (${parseFloat(((((o.p * p.a) / (p.b * p.q)) * 100) - 100).toFixed(2))}%)`
        }
        operations.push(operation)
      })

      return operations
  });

  return operations
}

// // set company orders
export const setCompanyOrders = (orders, old, wallet, localSymbols, mapOrders = []) => {
  const adapt = orders.filter(o => o.k).map(o => {

    const findSymbol = localSymbols.find(ls => ls.id === o.x)
    let findMapOrder = mapOrders.find(mo => mo.id === o.u)

    if (findSymbol !== undefined && o.s === "") {
      const { currency, group, bid, exchange } = findSymbol
      o.group = group
      o.currency = currency
      o.exchange = exchange
      o.symbol = `${group.toUpperCase()}${currency.toUpperCase()}`
      o.price = bid
    }

    const symbolWithExchange = `${(o.k || '').toUpperCase()}-${(o.s || '').toUpperCase()}`

    const opens = o.op.length
    const status = companyStatusFn(o)
    const entry = companyEntryFn(o)
    let inWallet = o.op.map(p => p.a).reduce((a, b)=> a + b, 0)
    inWallet = parseFloat(inWallet.toFixed(8))

    let free = 0;
    const wll = (wallet[(o.k || '').toLowerCase()] || []).filter(w => w.symbol.toLowerCase() === o.r.toLowerCase()).map(w => w.free)

    if (wll.length) free = wll.reduce((a, b)=> a + b, 0)

    free = parseFloat(free.toFixed(8))
    const inDifference = parseFloat((free - inWallet).toFixed(8))

    const deficit = parseFloat((o.op.map(p => ((p.a *  o.p) - (p.q *  p.b))).reduce((a, b)=> a + b, 0)).toFixed(2))
    const averagePrice = parseFloat(((o.op.map(p => p.q *  p.b).reduce((a, b) => a + b, 0)) / o.op.map(p => p.q).reduce((a, b) => a + b, 0)).toFixed(8))

    const perfilRender = perfilFn(o.v)
    const hold = { class: o.c > 0 ? "text-success" : "text-warning", label: o.c > 0 ? "on" : "off", value: 0.00 }
    const holdOn = o.c > 0 ? 1: 0

    if (findMapOrder === undefined) findMapOrder = {}

    return {
      id: o.u,
      gainUsd: (findMapOrder.gainUsd || 0),
      symbolOnHold: (findMapOrder.symbolOnHold || 0),
      symbolOnHoldAverageValue: parseFloat((findMapOrder.averageValue || 0).toFixed(2)),
      symbolOnHoldAverageValueNow: parseFloat((o.p * (findMapOrder.symbolOnHold || 0)).toFixed(2)),
      netIncome: parseFloat((((findMapOrder.gainUsd || 0) + (o.p * (findMapOrder.symbolOnHold || 0))) - (deficit || 0)).toFixed(2)),
      canBuy: o.n,
      symbol: o.s,
      symbol_id: o.x,
      profit: o.q,
      profit_save: o.c,
      profile: o.v,
      situation: o.m,
      buy_lock: o.l,
      w: wallet,
      buy_back: o.a,
      max: o.d,
      show: o.o,
      group: o.r,
      price: o.p,
      currency: o.t,
      value: o.y,
      value_min: o.i,
      value_max: o.f,
      buyIn: o.g,
      buyStop: o.h,
      sellIn: o.j,
      sellStop: o.z,
      orderId: o.id,
      countError: o.b,
      messageError: o.bm,
      exchange: (o.k || '').toUpperCase(),
      opens,
      status,
      entry,
      perfilRender,
      hold,
      wallet: free,
      inWallet,
      inDifference,
      group: o.r.toUpperCase(),
      deficit,
      averagePrice,
      symbolWithExchange,
      holdOn,
      operations: o.op.map(pp => ({
        id: pp.u,
        amount: pp.a,
        amount_buy: pp.q,
        buy: pp.b,
        commission_buy_type: pp.t,
        commission_buy: pp.w,
        commission_sell: pp.s,
        createdAt: pp.c,
        buy: parseFloat(pp.b.toFixed(8)),
        price: o.p,
        symbol: o.s.toUpperCase(),
        invested: parseFloat((pp.q * pp.b).toFixed(2)),
        currentValue: parseFloat((pp.a * o.p).toFixed(2)),
        situation: parseFloat(((((o.p * pp.a) / (pp.b * pp.q)) * 100) - 100).toFixed(2)),
        viewSituation: `${((o.p * pp.a) - (pp.b * pp.q)).toFixed(2)} (${parseFloat(((((o.p * pp.a) / (pp.b * pp.q)) * 100) - 100).toFixed(2))}%)`
      }))
    }
  })


  adapt.forEach(a => {
      const find = old.find(o => o.id === a.id)

      if (find) {
        find.opens = a.opens
        find.status = a.status
        find.entry = a.entry
        find.perfilRender = a.perfilRender
        find.buy_lock = a.buy_lock
        find.buyIn = a.buyIn
        find.max = a.max
        find.price = a.price
        find.buyStop = a.buyStop
        find.buy_back = a.buy_back
        find.countError = a.countError
        find.currency = a.currency
        find.messageError = a.messageError
        find.operations = a.operations
        find.perfil = a.perfil
        find.profit = a.profit
        find.profit_save = a.profit_save
        find.sellIn = a.sellIn
        find.sellStop = a.sellStop
        find.show = a.show
        find.situation = a.situation
        find.symbol = a.symbol
        find.exchange = a.exchange
        find.symbol_id = a.symbol_id
        find.value = a.value
        find.value_max = a.value_max
        find.value_min = a.value_min
        find.hold = a.hold
        find.wallet = a.wallet
        find.inWallet = a.inWallet
        find.inDifference = a.inDifference
        find.averagePrice = a.averagePrice
        find.deficit = a.deficit
        find.symbolWithExchange = a.symbolWithExchange
        find.holdOn = a.holdOn
        find.activeAnalysis = a.activeAnalysis
        find.gainUsd = a.gainUsd
        find.symbolOnHold = a.symbolOnHold
        find.symbolOnHoldAverageValue = a.symbolOnHoldAverageValue
        find.symbolOnHoldAverageValueNow = a.symbolOnHoldAverageValueNow
        find.netIncome = a.netIncome
      } else
        old.push({ ...a, request: false, selected: false })
  })

  return old
}

// set company user
export const setCompanyUser = (user, oldUsers, localSymbols = [], localOrders = []) => {
  const oldUser = oldUsers.find(u => u.id === user.u)
  const mapOrders = localOrders.filter(lo => lo.company_user_id === user.u)

  const wallets = {
    binance: user.b,
    kucoin: user.k,
    huobi: user.h,
    okex: user.o
  };

  oldUser.name = user.n
  oldUser.husdHuobi = parseFloat(user.c.h.toFixed(2))
  oldUser.usdtHuobi = parseFloat(user.c.a.toFixed(2))
  oldUser.usdtKucoin = parseFloat(user.c.k.toFixed(2))
  oldUser.usdtBinance = parseFloat(user.c.u.toFixed(2))
  oldUser.busdBinance = parseFloat(user.c.b.toFixed(2))
  oldUser.usdtOkex = parseFloat(user.c.o.toFixed(2))

  oldUser.suspendedBinance = user.s.b;
  oldUser.suspendedHuobi = user.s.h;
  oldUser.suspendedKucoin = user.s.k;
  oldUser.suspendedOkex = user.s.o;

  oldUser.wallets = wallets
  oldUser.orders = setCompanyOrders(user.os, oldUser.orders, wallets, localSymbols, mapOrders),
  oldUser.operations = setCompanyOperations(user.os, oldUser.operations)

  return oldUser
}

// compnay
export const setUsersCompany = (oldUsers, newUsers, verify, symbols, orders = []) => {
  if (verify === false) return newUsers

  if (oldUsers.length > 0) {
    const users = newUsers.filter(u => oldUsers.map(ou => ou.id).includes(u.u)).map(u => setCompanyUser(u, oldUsers, symbols, orders))
    oldUsers.forEach(oldUser => {
      const findUser = users.find(u => u.id === oldUser.id)
      if (findUser) {
        oldUser = findUser
      }
    });

  }

  return oldUsers
}

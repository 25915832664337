import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import store from '@/store';
import mixin from '@/mixins';
import VueGtag from "vue-gtag";

// router setup
import router from './routes/router';
import i18n from './i18n';
import './registerServiceWorker'

// select
import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.mixin(mixin);
Vue.use(VueGtag, { config: { id: "G-BG1ZTRBWBP" } }, router);

// components
import Balance from "@/components/Balance"
import Currency from "@/components/Currency"
import BrokerageSituation from "@/components/BrokerageSituation"

Vue.component('balance', Balance)
Vue.component('currency', Currency)
Vue.component('brokerage-situation', BrokerageSituation)

Vue.config.productionTip = false;
Vue.config.silent = true;

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  render: h => h(App),
  router,
  i18n
});

import axios from '@/services/config'
import { URI_ROMA, URI_BASE, URI_BASE_V2 } from '@/services/config'

export const runLogin = (args = {}) => {
  let query = "";
  if(args["login"]) query += `login: "${args["login"]}"`;
  if(args["password"]) query += `password: "${args["password"]}"`;
  if(args["twoFactors"]) query += `twoFactors: "${args["twoFactors"]}"`;


  return axios.post(URI_ROMA, { query: `mutation {
      generateToken(${query})
    }`
  })
}

export const loadAuth = () => {
  return axios.post(URI_ROMA, { query: `query { user { nickname usd husd busd usdt dai usdc usdt_k usdt_h pax tusd bnb brl btc eth system isAdmin, isCompany } }`})
}

export const charts = () => {
  return axios.post(URI_ROMA, { query: `query { sumProfitKucoin sumProfitCoinex sumProfitFtx sumProfitOkex monthlyGrossProfitKucoin monthlyGrossProfitCoinex monthlyGrossProfitFtx monthlyGrossProfitOkex lastSixMonthsEveryFortnightCoinex { M Q1 Q2 } lastSixMonthsEveryFortnightFtx { M Q1 Q2 } lastSixMonthsEveryFortnightKucoin { M Q1 Q2 } lastSixMonthsEveryFortnightOkex { M Q1 Q2 } lastThirtyDaysKucoin { value, day } lastThirtyDaysOkex { value, day } lastThirtyDaysCoinex { value, day } lastThirtyDaysFtx { value, day } symbolsOnHoldKucoin { symbol amount invested averageValue } symbolsOnHoldCoinex { symbol amount invested averageValue } symbolsOnHoldFtx { symbol amount invested averageValue } symbolsOnHoldOkex { symbol amount invested averageValue } sumProfitHuobi monthlyGrossProfitHuobi lastSixMonthsEveryFortnightHuobi { M Q1 Q2 } lastThirtyDaysHuobi { value, day } symbolsOnHoldHuobi { symbol amount invested averageValue } lastSixMonthsEveryFortnight { M Q1 Q2 } lastSixMonthsEveryFortnightBrl { M Q1 Q2 } lastThirtyDays { value, day } lastThirtyDaysBrl { value, day } monthlyGrossProfit monthlyGrossProfitBrl sumOfConfirmedAmountOnHold sumProfit sumProfitBrl symbolsOnHold { symbol amount invested averageValue } symbolsOnHoldBrl { symbol amount invested averageValue } }`})
    .then(({ data }) => data)
    .then(({ data }) => data)
}

export const overview = () => {
  return axios.post(URI_ROMA, { query: `query {  sumProfitKucoin sumProfitCoinex sumProfitFtx sumProfitOkex monthlyGrossProfitKucoin monthlyGrossProfitCoinex monthlyGrossProfitFtx  monthlyGrossProfitOkex lastSixMonthsEveryFortnightCoinex { M Q1 Q2 } lastSixMonthsEveryFortnightFtx { M Q1 Q2 } lastSixMonthsEveryFortnightKucoin { M Q1 Q2 } lastSixMonthsEveryFortnightOkex { M Q1 Q2 } lastThirtyDaysKucoin { value, day } lastThirtyDaysOkex { value, day } lastThirtyDaysCoinex { value, day } lastThirtyDaysFtx { value, day } symbolsOnHoldKucoin { symbol amount invested averageValue } symbolsOnHoldCoinex { symbol amount invested averageValue } symbolsOnHoldFtx { symbol amount invested averageValue } symbolsOnHoldOkex { symbol amount invested averageValue } sumProfitHuobi monthlyGrossProfitHuobi lastSixMonthsEveryFortnightHuobi { M Q1 Q2 } lastThirtyDaysHuobi { value, day } symbolsOnHoldHuobi { symbol amount invested averageValue } sumOfConfirmedAmountOnHold sumProfit symbolsOnHold { symbol amount invested averageValue } sumProfitBrl symbolsOnHoldBrl { symbol amount invested averageValue } }`})
    .then(({ data }) => data)
    .then(({ data }) => data)
}

export const symbolsOnHold = () => {
  return axios.post(URI_ROMA, { query: `query { symbolsOnHoldHuobi { symbol amount invested averageValue } symbolsOnHoldKucoin { symbol amount invested averageValue } symbolsOnHoldOkex { symbol amount invested averageValue } symbolsOnHold { symbol amount invested averageValue }  symbolsOnHoldBrl { symbol amount invested averageValue } }`})
    .then(({ data }) => data)
    .then(({ data }) => data)
}

export const verifyAccount = (exchange) => {
  return axios.post(URI_ROMA, { query: `mutation { verifyAccount(exchange: "${exchange}") }`})
    .then(({ data }) => data)
}

export const listBrokers = () => {
  return axios.post(URI_ROMA, { query: `query { listBrokers { name active blocked } }`})
    .then(({ data }) => data)
    .then(({ data }) => data)
}

export const newCode = (email) => {
  return axios.post(URI_BASE + '/new-code', { email });
}

export const changePassword = (data) => {
  return axios.post(URI_BASE + '/change-password', data);
}

export const sendPay = (data) => {
  return axios.post(URI_BASE + '/bank-statement', data);
}

export const sendPayConfirmingTransaction = (data) => {
  return axios.post(URI_BASE + '/bank-statement/confirming-transaction', data);
}

export const sendPayConfirmation = (tx, data) => {
  return axios.put(URI_BASE + `/bank-statement/${tx}/update`, data);
}


export const createUser = (data) => {
  return axios.post(URI_BASE + '/users', data);
}

export const iAlreadyTheAssets = (group, data) => {
  return axios.put(URI_BASE + `/orders/${group}/remove-hold`, data);
}

export const debitUnlockEvent = (data = {}) => {
  return axios.put(URI_BASE + `/user/debit-unlock-event`, data);
}

export const sellHold = (data) => {
  return axios.post(URI_BASE + '/operations/sell-hold', data);
}

export const listCloseOperations = (params = {}) => {
  return axios.get(URI_BASE + '/operations', { params });
}

export const sendContact = (data) => {
  return axios.post(URI_BASE + '/contact', data);
}

export const tutorialsApi = (params = {}) => {
  return axios.get(URI_BASE + '/tutoriais', { params });
}

export const indicationApi = (params = {}) => {
  return axios.get(URI_BASE + '/user/indication', params);
}

export const indicationsApi = (params = {}) => {
  return axios.get(URI_BASE + '/user/indications', params);
}

export const bankStatementsApi = (params = {}) => {
  return axios.get(URI_BASE + '/bank-statement', { params });
}

export const walletApi = (params = {}) => {
  return axios.get(URI_BASE + '/user/wallet', params);
}

export const userInfo = (params = {}) => {
  return axios.get(URI_BASE + '/user ', { params });
}

export const userData = async (params = {}) => {
  const { data } = await axios.get(URI_BASE + '/user ', { params });
  const { nickname, requestWithdrawal } = data

  return { name: nickname, requestWithdrawal }
}

export const setApi = (params = {}) => {
  return axios.put(URI_BASE + '/user/binance', params);
}

export const setIndicator = (params = {}) => {
  return axios.put(URI_BASE + '/user/set-indicator', params);
}

export const setApiFtx = (params = {}) => {
  return axios.put(URI_BASE + '/user/ftx', params);
}

export const setApiHuobi = (params = {}) => {
  return axios.put(URI_BASE + '/user/huobi', params);
}

export const setApiKucoin = (params = {}) => {
  return axios.put(URI_BASE + '/user/kucoin', params);
}

export const setApiOkex = (params = {}) => {
  return axios.put(URI_BASE + '/user/okex', params);
}

export const deleteApi = (data) => {
  return axios.post(URI_BASE + '/user/delete-api', data);
}

export const updateUser = (data) => {
  return axios.put(URI_BASE + '/user ', data);
}

export const twoFactors = (params = {}) => {
  return axios.get(URI_BASE + '/user/two-factors', params);
}

export const verifyTwoKey = (params = {}) => {
  return axios.put(URI_BASE + '/user/verify-two-key', params);
}

export const updateTelegram = (data) => {
  return axios.post(URI_BASE + '/telegram/update', data);
}

export const verifyTelegram = (params = {}) => {
  return axios.get(URI_BASE + '/telegram/verify', { params });
}

export const orderToggle = (id, data) => {
  return axios.put(URI_BASE + `/orders/${id}/toggle`, data);
}

export const listSymbols = (params = {}) => {
  return axios.get(URI_BASE + '/symbols', { params });
}

export const newOrder = (data) => {
  return axios.post(URI_BASE + '/orders', data);
}

export const editOrder = (id, data) => {
  return axios.put(URI_BASE + `/orders/${id}`, data);
}

export const notifications = (params = {}) => {
  return axios.get(URI_BASE + '/notifications', { params });
}

export const listOrders = (params = { gain: true}) => {
  return axios.get(URI_BASE + '/orders', { params });
}

export const manGroups = (params = {}) => {
  return axios.get(`${URI_BASE_V2}/company/user/man-groups`, { params });
}

export const symbolsPrice = (params = {}) => {
  return axios.get(`${URI_BASE}/symbols/price/info`, { params });
}


export const sellAccomplished = (data) => {
  return axios.post(URI_BASE + '/hold-accomplished', data);
}

export const sellInfo = (data) => {
  return axios.post(URI_BASE + '/hold-sell-info', data);
}

export const resetAll = (data) => {
  return axios.post(URI_BASE + '/user/reset-all', data);
}

export const usersList = (params = {}) => {
  return axios.get(URI_BASE + '/users', { params });
}

export const usersPut = (id, data) => {
  return axios.put(URI_BASE + `/users/${id}`, data);
}

export * from './compnay'

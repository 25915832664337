<template>
  <div class="col-md-12">
    <card v-if="exchange === 'binance'">
      <div class="row">
        <base-radio inline name="usd" class="mb-2 ml-3" :value="currentDefault" @input="changeCurrent">
          USD
        </base-radio>

        <base-radio inline name="brl" class="mb-2 ml-3" :value="currentDefault" @input="changeCurrent">
          BRL
        </base-radio>
      </div>
    </card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { BaseAlert, BaseRadio } from '@/components'

export default {
  name: 'Currency',
  props: ["ws"],
  components: {
    BaseAlert,
    BaseRadio
  },
  data() {
    return {
    };
  },
  methods: {
    ...mapActions({
      setChangeBroker: 'user/setChangeBroker',
      setCurrent: 'user/setCurrent',
    }),

    changeCurrent(value) {
      const data = { exchange: 'binance', value: value }
      this.setCurrent(data)
      this.setChangeBroker(false)
      setTimeout(() => this.setChangeBroker(true), 1600);
    }
  },
  created() {

  },
  computed:{
    ...mapGetters({
      exchange: 'user/exchange',
      currentDefault: 'user/currentDefault',
      blocked: 'user/blocked',
      onlyView: "user/onlyView"
    })
  },

  mounted() {

  }
};
</script>
<style scoped>
  strong.big {
    font-size: 1.1em;
    font-weight: 700;
  }

  .point {
    cursor: pointer;
  }
</style>

<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
    >
      <template slot="links">
        <sidebar-item
          :link="{ name: $t('sidebar.dashboard'), icon: 'tim-icons icon-chart-pie-36', path: '/dashboard' }"
        ></sidebar-item>

        <sidebar-item
          :link="{ name: $t('sidebar.overview'), icon: 'tim-icons icon-chart-bar-32', path: '/overview/account-status' }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{ name: $t('sidebar.errors'), icon: 'tim-icons icon-alert-circle-exc', path: '/errors' }"
        ></sidebar-item>

        <sidebar-item
          :link="{ name: $t('sidebar.orders'), icon: 'tim-icons icon-bag-16' }"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.allOrders'), path: '/orders/all' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.removedOrders'), path: '/orders/removed' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.openOperations'), path: '/orders/operations/open' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.closedOperations'), path: '/orders/operations/closed' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{ name: $t('sidebar.wallet'), icon: 'tim-icons icon-money-coins', path: '/wallet' }"
        ></sidebar-item>

        <sidebar-item
          :link="{ name: $t('sidebar.market'), icon: 'tim-icons icon-cart' }"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.currentSituation'), path: '/market/current-situation' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.ranking'), path: '/market/ranking' }"
          ></sidebar-item>
        </sidebar-item>


        <sidebar-item
          :link="{ name: $t('sidebar.financial'), icon: 'tim-icons icon-wallet-43', path: '/financial' }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{ name: $t('sidebar.referralProgram'), icon: 'tim-icons icon-badge', path: '/referral-program' }"
        ></sidebar-item>


        <sidebar-item
          :link="{ name: $t('sidebar.support'), icon: 'tim-icons icon-single-02' }"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.frequentlyAskedQuestions'), path: '/support/frequently-asked-questions' }"
          ></sidebar-item>

          <sidebar-item
            :link="{ name: $t('sidebar.contactUs'), path: '/support/contact-us' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{ name: $t('sidebar.settings'), icon: 'tim-icons icon-settings', path: '/settings' }"
        ></sidebar-item>

        <sidebar-item v-if="showAdmin"
          :link="{ name: $t('sidebar.admin'), icon: 'tim-icons icon-components', path: '/users' }"
        ></sidebar-item>

        <div class="mb-5"> </div>
        <div class="mb-5"> </div>
      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <sidebar-share> </sidebar-share>
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view :ws="ws"></router-view>
        </zoom-center-transition>
      </div>
      <content-footer :ws="ws" v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import SidebarShare from '@/pages/Layout/SidebarSharePlugin';
import { mapGetters, mapActions } from 'vuex'

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}


import socket from 'socket.io-client';
import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import { SlideYDownTransition, ZoomCenterTransition } from 'vue2-transitions';
import { loadAuth } from '@/services'
import { WSS } from '@/services/config'
import { setUsersCompany } from '../../adapters/index'

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition,
    SidebarShare
  },
  data() {
    return {
      ws: {},
      sidebarBackground: 'primary' //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    ...mapActions({
      setAuth: 'user/setAuth',
      setUserFtx: 'user/setUserFtx',
      setUserHuobi: 'user/setUserHuobi',
      setUserKucoin: 'user/setUserKucoin',
      setUserDefault: 'user/setUserDefault',
      setUserOkex: 'user/setUserOkex',
      setUser: 'user/setUser',
      setSymbols: 'symbols/setSymbols',
      setUsers: 'company/setUsers',
      setOnline: 'user/setOnline'
    }),
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    },
    loadAuth() {
      loadAuth()
        .then(({ data }) => {
          const { user } = data["data"]
          this.setAuth(user)
        })
        .catch(({ response }) => {
          const { data } = response;
          const errors = data.errors.map(e => e.message).join()
          alert(errors)
        })
    },
    onWs() {
      this.ws.on('binance:on:updated:symbols', symbols => this.setSymbols(symbols));
      this.ws.on('wss:user', user => this.setUser(user))
      this.ws.on('wss:user:ftx', user => this.setUserFtx(user))
      this.ws.on('wss:user:huobi', user => this.setUserHuobi(user))
      this.ws.on('wss:user:kucoin', user => this.setUserKucoin(user))
      this.ws.on('wss:user:default', user => this.setUserDefault(user))
      this.ws.on('wss:user:okex', user => this.setUserOkex(user))
      this.ws.on('wss:compnay:one', data => (data.event === 'users') ? this.setUsers(setUsersCompany(this.users, data.u, true, this.symbols, this.orders)): '')
      this.ws.on('online', value => this.setOnline(value))

      this.ws.on('wss:sell', message => this.$notify({ type: 'success', message , verticalAlign: 'top', horizontalAlign: 'center', timeout: 25000 }));
      this.ws.on('wss:buy', message => this.$notify({ type: 'warning', message , verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 20000 }));
      this.ws.on('wss:error', message => this.$notify({ type: 'danger', message , verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 30000 }));
    }
  },
  created() {
    this.loadAuth()

    const token = sessionStorage.getItem('token');

    const ops = {
      path: '/',
      allowUpgrades: false,
      transports: ['websocket'],
      query: { token }
    };

    this.ws = socket(WSS, ops)
  },
  mounted() {
    this.initScrollbar()
    this.onWs()
  },
  computed: {
    ...mapGetters({
      auth: 'user/auth',
      users: 'company/users',
      showAdmin: 'user/showAdmin',
      showCompany: 'user/showCompany',
      symbols: 'symbols/symbols',
      orders: 'company/orders'
    })
  },
  destroyed() {
    this.ws.disconnect()
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>

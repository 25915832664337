<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="javascript:void(0)">{{ routeName }}</a>
    </div>

    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        title-classes="nav-link"
        class="nav-item"
      >
        <template
          slot="title"
        >
          <div class="notification d-none d-lg-block d-xl-block"></div>
          <i class="tim-icons icon-chat-33"></i>
          <p class="d-lg-none">{{ $t("navBar.newNotifications") }}</p>
        </template>
        <li class="nav-link">
          <a href="javascript:void(0)" class="nav-item dropdown-item"
            > {{ $t("navBar.noNotification") }} </a
          >
        </li>
      </base-dropdown>
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar"
      >
        <template
          slot="title"
        >
          <div class="photo">
            <img src="/img/default-avatar.png" />
          </div>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none"> {{ auth.nickname || '' }} </p>
        </template>

        <li class="nav-link d-none d-md-none d-sm-none d-lg-block d-xl-block">
          <a href="javascript:void(0)" class="nav-item dropdown-item"> {{ auth.nickname || '' }}</a>
        </li>
        <div class="dropdown-divider"></div>
        <!-- <li class="nav-link">
          <a href="javascript:void(0)" class="nav-item dropdown-item"> {{ $t("navBar.profile") }} </a>
        </li> -->
        <li class="nav-link">
          <router-link class="nav-item dropdown-item" :to="{ name: 'Settings'}">
             {{ $t("navBar.settings") }}
          </router-link>
        </li>
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <a href="javascript:void(0)" class="nav-item dropdown-item" @click="logout($event)"> {{ $t("navBar.logout") }} </a>
        </li>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { mapGetters } from 'vuex'
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';

export default {
  components: {
    SidebarToggleButton,
    CollapseTransition,
    BaseNav,
    Modal
  },
  computed: {
    ...mapGetters({
      auth: 'user/auth'
    }),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(this.$t(`route.${name}`));
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: ''
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>

<template>
  <div class="fixed-plugin" v-click-outside="closeDropDown">
    <div class="dropdown show-dropdown" :class="{ show: isOpen }">
      <a data-toggle="dropdown" class="settings-icon">
        <i class="fa fa-cog fa-2x" @click="toggleDropDown"> </i>
      </a>
      <ul class="dropdown-menu" :class="{ show: isOpen }">

        <li class="header-title">{{ $t('sidebar.language') }}</li>
        <li class="adjustments-line">
          <div class="togglebutton switch-change-color mt-3">
            <span class="label-switch text-uppercase">{{ $t("sidebar.english") }}</span>
            <base-switch v-model="valueLang" @input="toggleLanguage"></base-switch>
            <span class="label-switch label-right text-uppercase">{{ $t("sidebar.portuguese") }}</span>
          </div>
        </li>

        <li class="header-title">{{ $t('sidebar.theme') }}</li>
        <li class="adjustments-line">
          <div class="togglebutton switch-change-color mt-3">
            <span class="label-switch text-uppercase">{{ $t("sidebar.light") }}</span>
            <base-switch v-model="darkMode" @input="toggleMode"></base-switch>
            <span class="label-switch label-right text-uppercase">{{ $t("sidebar.dark") }}</span>
          </div>
        </li>

        <li class="button-container mt-4"> </li>
      </ul>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import 'moment/locale/pt-br';
import pt_BR from 'vee-validate/dist/locale/pt_BR';
import en from 'vee-validate/dist/locale/en';
import { localize } from "vee-validate";
import { BaseSwitch } from 'src/components';
import { mapGetters, mapActions } from 'vuex'
import langEn from 'element-ui/lib/locale/lang/en';
import langPtBr from 'element-ui/lib/locale/lang/pt-br';
import locale from 'element-ui/lib/locale';
import { charts } from "@/services"
import { barMonthlyPerformance, lineDaysPerformance } from "../../pages/Dashboard/charts"

  export default {
    name: 'sidebar-share',
    components: {
      BaseSwitch
    },
    props: {
      backgroundColor: String
    },
    data() {
      return {
        sidebarMini: true,
        darkMode: false,
        isOpen: false,
        valueLang: false,
        usdCurrencyValue: true
      }
    },
    methods: {
      ...mapActions({
        setSymbolsOnHold: "user/setSymbolsOnHold",
        setSymbolsOnHoldBrl: "user/setSymbolsOnHoldBrl",
        setSumProfit: "user/setSumProfit",
        setDays: "user/setDays",
        setDaysBrl: "user/setDaysBrl",
        setMonths: "user/setMonths",
        setMonthsBrl: "user/setMonthsBrl",
        setSumProfitBrl: "user/setSumProfitBrl",
        setSumOfConfirmedAmountOnHold: "user/setSumOfConfirmedAmountOnHold",
        setMonthlyGrossProfit: "user/setMonthlyGrossProfit",
        setMonthlyGrossProfitBrl: "user/setMonthlyGrossProfitBrl",
        setLang: 'user/setLang',
        setTheme: 'user/setTheme',
        setUsdCurrency: 'user/setUsdCurrency',
        setChangeBroker: 'user/setChangeBroker',
      }),
      toggleDropDown() {
        this.isOpen = !this.isOpen;
      },
      closeDropDown() {
        this.isOpen = false;
      },
      toggleList(list, itemToActivate) {
        list.forEach(listItem => listItem.active = false);
        itemToActivate.active = true;
      },

      toggleMode(type) {
        let docClasses = document.body.classList;
        if (type) {
          docClasses.remove('white-content');
        } else {
          docClasses.add('white-content');
        }
        this.setTheme(type);
        this.darkMode = type
      },

      async toggleLanguage(type, reload = true) {
        const lang = (type === true) ? "pt_BR" : "en"
        this.setLang(lang)
        this.initLanguage()
        this.listCharts()
      },

      async listCharts() {

        if (!['Login', 'Register', 'Terms', 'Dashboard'].includes(this.$route.name)) {
          charts()
            .then(({
              sumProfitHuobi,
              sumProfitCoinex,
              sumProfitFtx,
              symbolsOnHoldHuobi,
              symbolsOnHoldKucoin,
              symbolsOnHoldCoinex,
              symbolsOnHoldFtx,
              symbolsOnHoldOkex,
              monthlyGrossProfitKucoin,
              monthlyGrossProfitCoinex,
              monthlyGrossProfitFtx,
              monthlyGrossProfitOkex,
              sumProfitKucoin,
              sumProfitOkex,
              lastThirtyDaysKucoin,
              lastThirtyDaysCoinex,
              lastThirtyDaysFtx,
              lastThirtyDaysOkex,
              lastSixMonthsEveryFortnightKucoin,
              lastSixMonthsEveryFortnightCoinex,
              lastSixMonthsEveryFortnightFtx,
              lastSixMonthsEveryFortnightOkex,
              lastSixMonthsEveryFortnight,
              monthlyGrossProfitHuobi,
              lastThirtyDaysHuobi,
              lastSixMonthsEveryFortnightHuobi,
              lastSixMonthsEveryFortnightBrl,
              lastThirtyDays,
              lastThirtyDaysBrl,
              monthlyGrossProfit = 0,
              monthlyGrossProfitBrl = 0,
              sumOfConfirmedAmountOnHold = 0,
              sumProfit = 0,
              sumProfitBrl = 0,
              symbolsOnHold = [],
              symbolsOnHoldBrl = [] }) => {

              // setSumProfit binance
              const binanceSumProfit = { exchange: 'binance', value: sumProfit }
              const binanceSumProfitBrl = { exchange: 'binance', value: sumProfitBrl }
              this.setSumProfit(binanceSumProfit)
              this.setSumProfitBrl(binanceSumProfitBrl)

              // setSumProfit huobi
              const huobiSumProfit = { exchange: 'huobi', value: sumProfitHuobi }
              this.setSumProfit(huobiSumProfit)

              // setSumProfit coinex
              const coinexSumProfit = { exchange: 'coinex', value: sumProfitCoinex }
              this.setSumProfit(coinexSumProfit)

              // setSumProfit ftx
              const ftxSumProfit = { exchange: 'ftx', value: sumProfitFtx }
              this.setSumProfit(ftxSumProfit)

              // setSumProfit kucoin
              const kucoinSumProfit = { exchange: 'kucoin', value: sumProfitKucoin }
              this.setSumProfit(kucoinSumProfit)

              // setSumProfit okex
              const okexSumProfit = { exchange: 'okex', value: sumProfitOkex }
              this.setSumProfit(okexSumProfit)

              // setSymbolsOnHold binance
              const binanceSymbolsOnHold = { exchange: 'binance', value: symbolsOnHold }
              const binanceSymbolsOnHoldBrl = { exchange: 'binance', value: symbolsOnHoldBrl }
              this.setSymbolsOnHold(binanceSymbolsOnHold)
              this.setSymbolsOnHoldBrl(binanceSymbolsOnHoldBrl)

              // setSymbolsOnHold huobi
              const huobiSymbolsOnHold = { exchange: 'huobi', value: symbolsOnHoldHuobi }
              this.setSymbolsOnHold(huobiSymbolsOnHold)

              // setSymbolsOnHold kucoin
              const kucoinSymbolsOnHold = { exchange: 'kucoin', value: symbolsOnHoldKucoin }
              this.setSymbolsOnHold(kucoinSymbolsOnHold)

              // setSymbolsOnHold coinex
              const coinexSymbolsOnHold = { exchange: 'coinex', value: symbolsOnHoldCoinex }
              this.setSymbolsOnHold(coinexSymbolsOnHold)

              // setSymbolsOnHold ftx
              const ftxSymbolsOnHold = { exchange: 'ftx', value: symbolsOnHoldFtx }
              this.setSymbolsOnHold(ftxSymbolsOnHold)

              // setSymbolsOnHold kucoin
              const okexSymbolsOnHold = { exchange: 'okex', value: symbolsOnHoldOkex }
              this.setSymbolsOnHold(okexSymbolsOnHold)

              // setMonthlyGrossProfit binance
              const binanceMonthlyGrossProfit = { exchange: 'binance', value: monthlyGrossProfit }
              const binanceMonthlyGrossProfitBrl = { exchange: 'binance', value: monthlyGrossProfitBrl }
              this.setMonthlyGrossProfit(binanceMonthlyGrossProfit)
              this.setMonthlyGrossProfitBrl(binanceMonthlyGrossProfitBrl)

              // setMonthlyGrossProfit coinex
              const coinexMonthlyGrossProfit = { exchange: 'coinex', value: monthlyGrossProfitCoinex }
              this.setMonthlyGrossProfit(coinexMonthlyGrossProfit)

              // setMonthlyGrossProfit ftx
              const ftxMonthlyGrossProfit = { exchange: 'ftx', value: monthlyGrossProfitFtx }
              this.setMonthlyGrossProfit(ftxMonthlyGrossProfit)

              // setMonthlyGrossProfit huobi
              const huobiMonthlyGrossProfit = { exchange: 'huobi', value: monthlyGrossProfitHuobi }
              this.setMonthlyGrossProfit(huobiMonthlyGrossProfit)

              // setMonthlyGrossProfit kucoin
              const kucoinMonthlyGrossProfit = { exchange: 'kucoin', value: monthlyGrossProfitKucoin }
              this.setMonthlyGrossProfit(kucoinMonthlyGrossProfit)

              // setMonthlyGrossProfit okex
              const okexMonthlyGrossProfit = { exchange: 'okex', value: monthlyGrossProfitOkex }
              this.setMonthlyGrossProfit(okexMonthlyGrossProfit)

              // setSumOfConfirmedAmountOnHold binance
              const binancesumOfConfirmedAmountOnHold = { exchange: 'binance', value: sumOfConfirmedAmountOnHold }
              this.setSumOfConfirmedAmountOnHold(binancesumOfConfirmedAmountOnHold)

              // set days binance
              const binanceDays = lineDaysPerformance()
              const binanceDaysBrl = lineDaysPerformance()
              lastThirtyDays.forEach(row => {
                binanceDays.chartData.labels.push(`${moment(row.day).format()}`)
                binanceDays.chartData.datasets[0].data.push(row.value)
              })
              lastThirtyDaysBrl.forEach(row => {
                binanceDaysBrl.chartData.labels.push(`${moment(row.day).format()}`)
                binanceDaysBrl.chartData.datasets[0].data.push(row.value)
              })
              const binanceDaysData = { exchange: 'binance', value: binanceDays }
              const binanceDaysBrlData = { exchange: 'binance', value: binanceDaysBrl }
              this.setDays(binanceDaysData)
              this.setDaysBrl(binanceDaysBrlData)

              // set days huobi
              const huobiDays = lineDaysPerformance()
              lastThirtyDaysHuobi.forEach(row => {
                huobiDays.chartData.labels.push(`${moment(row.day).format()}`)
                huobiDays.chartData.datasets[0].data.push(row.value)
              })
              const huobiDaysDaysData = { exchange: 'huobi', value: huobiDays }
              this.setDays(huobiDaysDaysData)

              // set days ftx
              const ftxDays = lineDaysPerformance()
              lastThirtyDaysFtx.forEach(row => {
                ftxDays.chartData.labels.push(`${moment(row.day).format()}`)
                ftxDays.chartData.datasets[0].data.push(row.value)
              })
              const ftxDaysDaysData = { exchange: 'ftx', value: ftxDays }
              this.setDays(ftxDaysDaysData)

              // set days coinex
              const coinexDays = lineDaysPerformance()
              lastThirtyDaysCoinex.forEach(row => {
                coinexDays.chartData.labels.push(`${moment(row.day).format()}`)
                coinexDays.chartData.datasets[0].data.push(row.value)
              })
              const coinexDaysDaysData = { exchange: 'coinex', value: coinexDays }
              this.setDays(coinexDaysDaysData)

              // setDays kucoin
              const kucoinDays = lineDaysPerformance()
              lastThirtyDaysKucoin.forEach(row => {
                kucoinDays.chartData.labels.push(`${moment(row.day).format()}`)
                kucoinDays.chartData.datasets[0].data.push(row.value)
              })
              const kucoinDaysDaysData = { exchange: 'kucoin', value: kucoinDays }
              this.setDays(kucoinDaysDaysData)

              // setDays okex
              const okexDays = lineDaysPerformance()
              lastThirtyDaysOkex.forEach(row => {
                okexDays.chartData.labels.push(`${moment(row.day).format()}`)
                okexDays.chartData.datasets[0].data.push(row.value)
              })
              const okexDaysDaysData = { exchange: 'okex', value: okexDays }
              this.setDays(okexDaysDaysData)

              // lastSixMonthsEveryFortnight binance
              const binanceMonthlys = barMonthlyPerformance()
              const binanceMonthlysBrl = barMonthlyPerformance()
              lastSixMonthsEveryFortnight.reverse()
              lastSixMonthsEveryFortnight.forEach(row => {
                binanceMonthlys.chartData.labels.push(`${moment(row.M).format("MMMM YYYY")}`)
                binanceMonthlys.chartData.datasets[0].data.push(row.Q1)
                binanceMonthlys.chartData.datasets[1].data.push(row.Q2)
              })
              lastSixMonthsEveryFortnightBrl.reverse()
              lastSixMonthsEveryFortnightBrl.forEach(row => {
                binanceMonthlysBrl.chartData.labels.push(`${moment(row.M).format("MM YYYY")}`)
                binanceMonthlysBrl.chartData.datasets[0].data.push(row.Q1)
                binanceMonthlysBrl.chartData.datasets[1].data.push(row.Q2)
              })
              const binanceMonthlyData = { exchange: 'binance', value: binanceMonthlys }
              const binanceMonthlyBrlData = { exchange: 'binance', value: binanceMonthlysBrl }
              this.setMonths(binanceMonthlyData)
              this.setMonthsBrl(binanceMonthlyBrlData)


              const huobiMonthlys = barMonthlyPerformance()
              lastSixMonthsEveryFortnightHuobi.reverse()
              lastSixMonthsEveryFortnightHuobi.forEach(row => {
                huobiMonthlys.chartData.labels.push(`${moment(row.M).format("MMMM YYYY")}`)
                huobiMonthlys.chartData.datasets[0].data.push(row.Q1)
                huobiMonthlys.chartData.datasets[1].data.push(row.Q2)
              })
              const huobiMonthlyData = { exchange: 'huobi', value: huobiMonthlys }
              this.setMonths(huobiMonthlyData)


              const kucoinMonthlys = barMonthlyPerformance()
              lastSixMonthsEveryFortnightKucoin.reverse()
              lastSixMonthsEveryFortnightKucoin.forEach(row => {
                kucoinMonthlys.chartData.labels.push(`${moment(row.M).format("MMMM YYYY")}`)
                kucoinMonthlys.chartData.datasets[0].data.push(row.Q1)
                kucoinMonthlys.chartData.datasets[1].data.push(row.Q2)
              })
              const kucoinMonthlyData = { exchange: 'kucoin', value: kucoinMonthlys }
              this.setMonths(kucoinMonthlyData)


              const okexMonthlys = barMonthlyPerformance()
              lastSixMonthsEveryFortnightOkex.reverse()
              lastSixMonthsEveryFortnightOkex.forEach(row => {
                okexMonthlys.chartData.labels.push(`${moment(row.M).format("MMMM YYYY")}`)
                okexMonthlys.chartData.datasets[0].data.push(row.Q1)
                okexMonthlys.chartData.datasets[1].data.push(row.Q2)
              })
              const okexMonthlyData = { exchange: 'okex', value: okexMonthlys }
              this.setMonths(okexMonthlyData)


              const coinexMonthlys = barMonthlyPerformance()
              lastSixMonthsEveryFortnightCoinex.reverse()
              lastSixMonthsEveryFortnightCoinex.forEach(row => {
                coinexMonthlys.chartData.labels.push(`${moment(row.M).format("MMMM YYYY")}`)
                coinexMonthlys.chartData.datasets[0].data.push(row.Q1)
                coinexMonthlys.chartData.datasets[1].data.push(row.Q2)
              })
              const coinexMonthlyData = { exchange: 'coinex', value: coinexMonthlys }
              this.setMonths(coinexMonthlyData)


              const ftxMonthlys = barMonthlyPerformance()
              lastSixMonthsEveryFortnightFtx.reverse()
              lastSixMonthsEveryFortnightFtx.forEach(row => {
                ftxMonthlys.chartData.labels.push(`${moment(row.M).format("MMMM YYYY")}`)
                ftxMonthlys.chartData.datasets[0].data.push(row.Q1)
                ftxMonthlys.chartData.datasets[1].data.push(row.Q2)
              })
              const ftxMonthlyData = { exchange: 'ftx', value: ftxMonthlys }
              this.setMonths(ftxMonthlyData)
            })
            .then(() => {
              this.setChangeBroker(false)
              setTimeout(() => this.setChangeBroker(true), 1600);
            })

        }
      },

      minimizeSidebar() {
        this.$sidebar.toggleMinimize();
      },
      initLanguage() {
        // set language
        if (this.lang === "pt_BR") {

          locale.use(langPtBr);
          localize("pt_BR", pt_BR)
          moment.locale("pt-br")
          moment.defaultFormat = "DD MMMM YYYY"

          this.valueLang = true
          this.$root.$i18n.locale = "br"
          this.$root.$i18n.fallbackLocale = "br"
        }
        else {
          localize("en", en)
          locale.use(langEn);
          moment.locale("en")
          moment.defaultFormat = "MMMM DD YYYY"

          this.valueLang = false
          this.$root.$i18n.locale = "en"
          this.$root.$i18n.fallbackLocale = "en"
        }
      },
      verifyLang() {
        const lang = localStorage.getItem("valueLang")
        if (lang === "pt_BR")
          this.toggleLanguage(true, false)
        if (lang === "en")
          this.toggleLanguage(false, false)
        else
          this.toggleLanguage(true, false)
      },
      verifyTheme() {
        const theme = localStorage.getItem("theme")
        const value = (theme === "true") ? true : false
        this.toggleMode(value)
      }
    },
    created() {
      this.verifyLang()
      this.verifyTheme()
    },
    computed: {
      ...mapGetters({
        lang: 'user/lang',
        theme: 'user/theme'
      })
    }
  };
</script>
<style scoped lang="scss">
  @import '~@/assets/sass/dashboard/custom/variables';

  .settings-icon {
    cursor: pointer;
  }

  .badge-vue {
    background-color: $vue;
  }
</style>

import { setOperations, setOrders, setRiskRate, setDeficit, setValueInPurchases, setRiskRateUsd, setValueWallet } from "../adapters"
import { barMonthlyPerformance, lineDaysPerformance, lineDaysPerformanceBrl, barMonthlyPerformanceBrl } from "../pages/Dashboard/charts"

export default {
  namespaced: true,
  state: {
    exchange: 'binance',
    theme: true,
    showBalance: true,
    changeBroker: true,
    canChangeExchange: true,
    token: "",
    valueLang: "en",
    auth: { nickname: "" },
    orders: [],
    binance: {
      days: lineDaysPerformance(),
      months: barMonthlyPerformance(),
      daysBrl: lineDaysPerformanceBrl(),
      monthsBrl: barMonthlyPerformanceBrl(),
      sumProfit: 0,
      sumProfitBrl: 0,
      monthlyGrossProfit: 0,
      monthlyGrossProfitBrl: 0,
      sumOfConfirmedAmountOnHold: 0,
      deficit: 0,
      deficitBrl: 0,
      riskRate: 0,
      riskRateBrl: 0,
      capitalAtRisk: 0,
      capitalAtRiskBrl: 0,
      valueInPurchases: 0,
      valueInPurchasesBrl: 0,
      allowPrice: {
        a: 'USDT',
        b: 'BUSD'
      },
      currency: {
        usdt: 0,
        busd: 0,
        usdc: 0,
        dai: 0,
        tusd: 0,
        pax: 0,
        brl: 0
      },
      current: {
        default: 'usd',
        usd: "USDT",
        brl: "BRL"
      },
      wallet: [],
      walletList: [],
      orders: [],
      openOperations: [],
      localSymbols: [],
      symbolsOnHold: [],
      symbolsOnHoldBrl: [],
      blocked: false,
      down: false
    },
    ftx: {
      days: lineDaysPerformance(),
      months: barMonthlyPerformance(),
      daysBrl: lineDaysPerformanceBrl(),
      monthsBrl: barMonthlyPerformanceBrl(),
      sumProfit: 0,
      sumProfitBrl: 0,
      monthlyGrossProfit: 0,
      monthlyGrossProfitBrl: 0,
      sumOfConfirmedAmountOnHold: 0,
      deficit: 0,
      deficitBrl: 0,
      riskRate: 0,
      riskRateBrl: 0,
      capitalAtRisk: 0,
      capitalAtRiskBrl: 0,
      valueInPurchases: 0,
      valueInPurchasesBrl: 0,
      allowPrice: {
        a: 'USD',
        b: 'USD'
      },
      currency: {
        usd: 0
      },
      current: {
        default: 'usd',
        usd: "USD",
      },
      wallet: [],
      walletList: [],
      orders: [],
      openOperations: [],
      localSymbols: [],
      symbolsOnHold: [],
      symbolsOnHoldBrl: [],
      blocked: false,
      down: false
    },
    huobi: {
      days: lineDaysPerformance(),
      months: barMonthlyPerformance(),
      daysBrl: lineDaysPerformanceBrl(),
      monthsBrl: barMonthlyPerformanceBrl(),
      sumProfit: 0,
      sumProfitBrl: 0,
      monthlyGrossProfit: 0,
      monthlyGrossProfitBrl: 0,
      sumOfConfirmedAmountOnHold: 0,
      deficit: 0,
      deficitBrl: 0,
      riskRate: 0,
      riskRateBrl: 0,
      capitalAtRisk: 0,
      capitalAtRiskBrl: 0,
      valueInPurchases: 0,
      valueInPurchasesBrl: 0,
      allowPrice: {
        a: 'USDT',
        b: 'USDT'
      },
      currency: {
        usdt: 0
      },
      current: {
        default: 'usd',
        usd: "USDT",
      },
      wallet: [],
      walletList: [],
      orders: [],
      openOperations: [],
      localSymbols: [],
      symbolsOnHold: [],
      symbolsOnHoldBrl: [],
      blocked: false,
      down: false
    },
    coinex: {
      days: lineDaysPerformance(),
      months: barMonthlyPerformance(),
      daysBrl: lineDaysPerformanceBrl(),
      monthsBrl: barMonthlyPerformanceBrl(),
      sumProfit: 0,
      sumProfitBrl: 0,
      monthlyGrossProfit: 0,
      monthlyGrossProfitBrl: 0,
      sumOfConfirmedAmountOnHold: 0,
      deficit: 0,
      deficitBrl: 0,
      riskRate: 0,
      riskRateBrl: 0,
      capitalAtRisk: 0,
      capitalAtRiskBrl: 0,
      valueInPurchases: 0,
      valueInPurchasesBrl: 0,
      allowPrice: {
        a: 'USDT',
        b: 'USDC'
      },
      currency: {
        usdt: 0,
        usdc: 0
      },
      current: {
        default: 'usd',
        usd: "USDT",
      },
      wallet: [],
      walletList: [],
      orders: [],
      openOperations: [],
      localSymbols: [],
      symbolsOnHold: [],
      symbolsOnHoldBrl: [],
      blocked: false,
      down: false
    },
    kucoin: {
      days: lineDaysPerformance(),
      months: barMonthlyPerformance(),
      daysBrl: lineDaysPerformanceBrl(),
      monthsBrl: barMonthlyPerformanceBrl(),
      sumProfit: 0,
      sumProfitBrl: 0,
      monthlyGrossProfit: 0,
      monthlyGrossProfitBrl: 0,
      sumOfConfirmedAmountOnHold: 0,
      deficit: 0,
      deficitBrl: 0,
      riskRate: 0,
      riskRateBrl: 0,
      capitalAtRisk: 0,
      capitalAtRiskBrl: 0,
      valueInPurchases: 0,
      valueInPurchasesBrl: 0,
      allowPrice: {
        a: 'USDT',
        b: 'USDT'
      },
      currency: {
        usdt: 0
      },
      current: {
        default: 'usd',
        usd: "USDT",
      },
      wallet: [],
      walletList: [],
      orders: [],
      openOperations: [],
      localSymbols: [],
      symbolsOnHold: [],
      symbolsOnHoldBrl: [],
      blocked: false,
      down: false
    },
    okex: {
      days: lineDaysPerformance(),
      months: barMonthlyPerformance(),
      daysBrl: lineDaysPerformanceBrl(),
      monthsBrl: barMonthlyPerformanceBrl(),
      sumProfit: 0,
      sumProfitBrl: 0,
      monthlyGrossProfit: 0,
      monthlyGrossProfitBrl: 0,
      sumOfConfirmedAmountOnHold: 0,
      deficit: 0,
      deficitBrl: 0,
      riskRate: 0,
      riskRateBrl: 0,
      capitalAtRisk: 0,
      capitalAtRiskBrl: 0,
      valueInPurchases: 0,
      valueInPurchasesBrl: 0,
      allowPrice: {
        a: 'USDT',
        b: 'USDT'
      },
      currency: {
        usdt: 0
      },
      current: {
        default: 'usd',
        usd: "USDT",
      },
      wallet: [],
      walletList: [],
      orders: [],
      openOperations: [],
      localSymbols: [],
      symbolsOnHold: [],
      symbolsOnHoldBrl: [],
      blocked: false,
      down: false
    },
    gain: [],
    synchronize: false,
    usdCurrency: true,
    showAdmin: false,
    showCompany: false,
    online: 0,
    onlyView: false
  },
  actions: {
    setExchange({ commit }, value) {
      commit('SET_EXCHANGE', value );
    },

    setCanChangeExchange({ commit }, value) {
      commit('CAN_CHANGE_EXCHANGE', value );
    },

    setChangeBroker({ commit }, value) {
      commit('CHANGE_BROKER', value );
    },

    setCurrent({ commit }, data) {
      commit('CURRENT', data );
    },

    setAuth({ commit }, auth) {
      commit('SET_AUTH', auth );
    },

    setShowAdmin({ commit }, value) {
      commit('SET_SHOW_ADMIN', value );
    },

    setDays({ commit }, data) {
      commit('DAYS', data );
    },

    setDaysBrl({ commit }, data) {
      commit('DAYS_BRL', data );
    },

    setMonths({ commit }, data) {
      commit('MONTHS', data );
    },

    setMonthsBrl({ commit }, data) {
      commit('MONTHS_BRL', data );
    },

    setMonthlyGrossProfit({ commit }, data) {
      commit('MONTHLY_GROSS_PROFIT', data );
    },

    setMonthlyGrossProfitBrl({ commit }, data) {
      commit('MONTHLY_GROSS_PROFIT_BRL', data );
    },

    setSumOfConfirmedAmountOnHold({ commit }, data) {
      commit('SUM_OF_CONFIRMED_AMOUNT_ON_HOLD', data );
    },

    setSumProfit({ commit }, data) {
      commit('SET_SUM_PROFIT', data );
    },

    setSumProfitBrl({ commit }, data) {
      commit('SET_SUM_PROFIT_BRL', data );
    },

    setOnline({ commit }, value) {
      commit('SET_ONLINE', value );
    },

    setSymbolsOnHold({ commit }, data) {
      commit('SET_SOH', data );
    },

    setSymbolsOnHoldBrl({ commit }, data) {
      commit('SET_SOH_BRL', data );
    },

    setGain({ commit }, gain) {
      commit('SET_GAIN', gain );
    },

    setSynchronize({ commit }, synchronize) {
      commit('SET_SYN', synchronize );
    },

    setUserDefault({ commit, rootState }, user) {
      const { symbols } = rootState
      const { rows } = symbols

      const defaultExchange = {
        localSymbols: rows,
        blocked: user.adminBlock,
        blockedUser: user.adminBlock,
        down: user.down,
        id: user.id,
        name: user.nickname,
        nickname: user.nickname,
        updateStop: user.down
      }

      const kucoin = {
        ...defaultExchange,
        currency: { usdt: user.kucoin.w.usdt || 0, usdc: user.kucoin.w.usdc || 0 },
        orders: user.orders.filter(o => (o.exchange || '').toLowerCase() === "kucoin"),
        wallet: Object.entries(user.kucoin.w).map(item => ({ symbol: item[0], free: item[1] })),
        exchange: 'kucoin'
      }

      const huobi = {
        ...defaultExchange,
        currency: { usdt: user.huobi.w.usdt || 0, husd: user.huobi.w.husd || 0, usdc: user.huobi.w.usdc || 0 },
        orders: user.orders.filter(o => (o.exchange || '').toLowerCase() === "huobi"),
        wallet: Object.entries(user.huobi.w).map(item => ({ symbol: item[0], free: item[1] })),
        exchange: 'huobi'
      }

      const ftx = {
        ...defaultExchange,
        currency: { usdt: user.ftx.w.usdt || 0, usd: user.ftx.w.usd || 0 },
        orders: user.orders.filter(o => (o.exchange || '').toLowerCase() === "ftx"),
        wallet: Object.entries(user.ftx.w).map(item => ({ symbol: item[0], free: item[1] })),
        exchange: 'ftx'
      }

      const okex = {
        ...defaultExchange,
        currency: { usdt: user.okex.w.usdt || 0, usdc: user.okex.w.usdc || 0 },
        orders: user.orders.filter(o => (o.exchange || '').toLowerCase() === "okex"),
        wallet: Object.entries(user.okex.w).map(item => ({ symbol: item[0], free: item[1] })),
        exchange: 'okex'
      }

      const coinex = {
        ...defaultExchange,
        currency: { usdt: user.coinex.w.usdt || 0, usdc: user.coinex.w.usdc || 0 },
        orders: user.orders.filter(o => (o.exchange || '').toLowerCase() === "coinex"),
        wallet: Object.entries(user.coinex.w).map(item => ({ symbol: item[0], free: item[1] })),
        exchange: 'coinex'
      }

      const binance = {
        ...defaultExchange,
        currency: {
          usdt: user.binance.w.usdt || 0,
          usdc: user.binance.w.usdc || 0,
          busd: user.binance.w.busd || 0,
          dai: user.binance.w.dai || 0,
          brl: user.binance.w.brl || 0,
          tusd: user.binance.w.tusd || 0,
          pax: user.binance.w.pax || 0
        },
        orders: user.orders.filter(o => (o.exchange || '').toLowerCase() === "binance"),
        wallet: Object.entries(user.binance.w).map(item => ({ symbol: item[0], free: item[1] })),
        exchange: 'binance'
      }

      commit('SET_USER_DEFAULT', kucoin)
      commit('SET_USER_DEFAULT', huobi)
      commit('SET_USER_DEFAULT', ftx)
      commit('SET_USER_DEFAULT', okex)
      commit('SET_USER_DEFAULT', coinex)
      // commit('SET_USER_DEFAULT', binance)
    },

    setUser({ commit, rootState }, user) {
      const { symbols } = rootState
      const { rows } = symbols

      user['localSymbols'] = rows
      commit('SET_USER', user );
    },

    setUserFtx({ commit, rootState }, user) {
      const { symbols } = rootState
      const { rows } = symbols

      user['localSymbols'] = rows
      commit('SET_USER_FTX', user );
    },

    setUserHuobi({ commit, rootState }, user) {
      const { symbols } = rootState
      const { rows } = symbols

      user['localSymbols'] = rows
      commit('SET_USER_HUOBI', user );
    },

    setUserKucoin({ commit, rootState }, user) {
      const { symbols } = rootState
      const { rows } = symbols

      user['localSymbols'] = rows
      commit('SET_USER_KUCOIN', user );
    },

    setUserOkex({ commit, rootState }, user) {
      const { symbols } = rootState
      const { rows } = symbols

      user['localSymbols'] = rows
      commit('SET_USER_OKEX', user );
    },

    setShowBalance({ commit }, show) {
      commit('SET_SHOW_BALANCE', show );
    },

    setToken({ commit }, token) {
      commit('SET_TOKEN', token );
    },

    setLang({ commit }, token) {
      commit('SET_LANG', token );
    },

    setTheme({ commit }, theme) {
      commit('SET_THEME', theme );
    },

    setUsdCurrency({ commit }, usdCurrency) {
      commit('SET_USD_CURRENCY', usdCurrency );
    }
  },
  mutations: {
    SET_EXCHANGE( state, value ) {
      const allow = ['binance', 'ftx', 'huobi', 'kucoin', 'okex', 'coinex']
      if (allow.includes(value)) {
        state.exchange = value;
      }
    },

    SET_AUTH( state, auth ) {
      const { nickname, usdt, busd, usdc, dai, tusd, pax, brl, usd = 0, husd = 0, usdt_k = 0, usdt_h = 0, isCompany, system, isAdmin } = auth


      state.binance.currency = { usdt, busd, usdc, dai, tusd, pax, brl }
      state.ftx.currency = { usd }
      state.huobi.currency = { husd, usdt: usdt_h }
      state.kucoin.currency = { usdt: usdt_k }

      if (isCompany === true) {
        state.showCompany = true
      }

      if (system === true || isAdmin === true) {
        state.showAdmin = true
      }

      state.auth = { ...auth };
    },

    SET_SHOW_ADMIN( state, value ) {
      state.showAdmin = value;
    },

    CAN_CHANGE_EXCHANGE( state, value ) {
      state.canChangeExchange = value;
    },

    CHANGE_BROKER( state, value ) {
      state.changeBroker = value;
    },

    DAYS( state, data ) {
      const { exchange, value } = data
      state[exchange].days = value
    },

    DAYS_BRL( state, data ) {
      const { exchange, value } = data
      state[exchange].daysBrl = value
    },

    CURRENT( state, data ) {
      const { exchange, value } = data

      const allow = {
        binance: ['brl', 'usd'],
        ftx: ['usd'],
        huobi: ['usd'],
        kucoin: ['usd']
      }
      if (allow[exchange].includes(value)) {
        state[exchange].current.default = value
      }
    },

    MONTHS( state, data ) {
      const { exchange, value } = data
      state[exchange].months = value
    },

    MONTHS_BRL( state, data ) {
      const { exchange, value } = data
      state[exchange].monthsBrl = value
    },

    MONTHLY_GROSS_PROFIT( state, data ) {
      const { exchange, value } = data
      state[exchange].monthlyGrossProfit = value
    },

    MONTHLY_GROSS_PROFIT_BRL( state, data ) {
      const { exchange, value } = data
      state[exchange].monthlyGrossProfitBrl = value
    },

    SET_SOH( state, data ) {
      const { exchange, value } = data
      state[exchange].symbolsOnHold = value.filter(v => v.amount > 0)
    },

    SET_SOH_BRL( state, data ) {
      const { exchange, value } = data
      state[exchange].symbolsOnHoldBrl = value.filter(v => v.amount > 0)
    },

    SET_USD_CURRENCY( state, usdCurrency ) {
      state.usdCurrency = usdCurrency;
      localStorage.setItem('usdCurrency', usdCurrency)
    },

    SET_GAIN( state, gain ) {
      state.gain = gain;
    },

    SET_ONLINE( state, value ) {
      state.online = value
    },

    SET_SUM_PROFIT( state, data ) {
      const { exchange, value } = data
      state[exchange].sumProfit = value
    },

    SET_SUM_PROFIT_BRL( state, data ) {
      const { exchange, value } = data
      state[exchange].sumProfitBrl = value
    },

    SUM_OF_CONFIRMED_AMOUNT_ON_HOLD( state, data ) {
      const { exchange, value } = data
      state[exchange].sumOfConfirmedAmountOnHold = value
    },

    SET_SYN( state, synchronize ) {
      state.synchronize = synchronize;
    },

    SET_USER( state, user) {
      const { currency, wallet, orders,  blocked, down, localSymbols } = user

      // new
      state.binance.currency = currency
      state.binance.wallet = wallet
      state.binance.blocked = blocked
      state.binance.down = down
      state.binance.localSymbols = localSymbols
      state.binance.orders = setOrders(orders, state.binance.orders, state.binance.wallet, state.binance.localSymbols, state.gain)
      state.binance.openOperations = setOperations(orders, state.binance.openOperations)
      state.binance.deficit = setDeficit(orders)
      state.binance.deficitBrl = setDeficit(orders, 'brl')
      state.binance.riskRate = setRiskRate(orders, currency, 0)
      state.binance.riskRateBrl = setRiskRate(orders, currency, 0, 'brl')
      state.binance.capitalAtRisk = setRiskRateUsd(orders)
      state.binance.capitalAtRiskBrl = setRiskRateUsd(orders, 'brl')
      state.binance.valueInPurchases = setValueInPurchases(orders)
      state.binance.valueInPurchasesBrl = setValueInPurchases(orders, 'brl')
      state.binance.walletList = setValueWallet(state.binance.walletList, wallet, orders)
    },

    SET_USER_FTX( state, user) {
      const { currency, wallet, orders,  blocked, down, localSymbols } = user
      state.ftx.currency = currency
      state.ftx.wallet = wallet
      state.ftx.blocked = blocked
      state.ftx.down = down
      state.ftx.localSymbols = localSymbols
      state.ftx.orders = setOrders(orders, state.ftx.orders, state.ftx.wallet, state.ftx.localSymbols, state.gain)
      state.ftx.openOperations = setOperations(orders, state.ftx.openOperations)
      state.ftx.deficit = setDeficit(orders)
      state.ftx.deficitBrl = setDeficit(orders, 'brl')
      state.ftx.riskRate = setRiskRate(orders, currency, 0)
      state.ftx.riskRateBrl = setRiskRate(orders, currency, 0, 'brl')
      state.ftx.capitalAtRisk = setRiskRateUsd(orders)
      state.ftx.capitalAtRiskBrl = setRiskRateUsd(orders, 'brl')
      state.ftx.valueInPurchases = setValueInPurchases(orders)
      state.ftx.valueInPurchasesBrl = setValueInPurchases(orders, 'brl')
      state.ftx.walletList = setValueWallet(state.ftx.walletList, wallet, orders)
    },

    SET_USER_HUOBI( state, user) {
      const { currency, wallet, orders,  blocked, down, localSymbols } = user
      const oneCurrency = { husd: currency.husd, usdt: currency.usdt_h }

      state.huobi.currency = oneCurrency
      state.huobi.wallet = wallet
      state.huobi.blocked = blocked
      state.huobi.down = down
      state.huobi.localSymbols = localSymbols
      state.huobi.orders = setOrders(orders, state.huobi.orders, state.huobi.wallet, state.huobi.localSymbols, state.gain)
      state.huobi.openOperations = setOperations(orders, state.huobi.openOperations)
      state.huobi.deficit = setDeficit(orders)
      state.huobi.deficitBrl = setDeficit(orders, 'brl')
      state.huobi.riskRate = setRiskRate(orders, oneCurrency, 0)
      state.huobi.riskRateBrl = setRiskRate(orders, oneCurrency, 0, 'brl')
      state.huobi.capitalAtRisk = setRiskRateUsd(orders)
      state.huobi.capitalAtRiskBrl = setRiskRateUsd(orders, 'brl')
      state.huobi.valueInPurchases = setValueInPurchases(orders)
      state.huobi.valueInPurchasesBrl = setValueInPurchases(orders, 'brl')
      state.huobi.walletList = setValueWallet(state.huobi.walletList, wallet, orders)
    },

    SET_USER_KUCOIN( state, user) {
      const { currency, wallet, orders,  blocked, down, localSymbols } = user
      state.kucoin.currency = currency
      state.kucoin.wallet = wallet
      state.kucoin.blocked = blocked
      state.kucoin.down = down
      state.kucoin.localSymbols = localSymbols
      state.kucoin.orders = setOrders(orders, state.kucoin.orders, state.kucoin.wallet, state.kucoin.localSymbols, state.gain)
      state.kucoin.openOperations = setOperations(orders, state.kucoin.openOperations)
      state.kucoin.deficit = setDeficit(orders)
      state.kucoin.deficitBrl = setDeficit(orders, 'brl')
      state.kucoin.riskRate = setRiskRate(orders, currency, 0)
      state.kucoin.riskRateBrl = setRiskRate(orders, currency, 0, 'brl')
      state.kucoin.capitalAtRisk = setRiskRateUsd(orders)
      state.kucoin.capitalAtRiskBrl = setRiskRateUsd(orders, 'brl')
      state.kucoin.valueInPurchases = setValueInPurchases(orders)
      state.kucoin.valueInPurchasesBrl = setValueInPurchases(orders, 'brl')
      state.kucoin.walletList = setValueWallet(state.kucoin.walletList, wallet, orders)
    },

    SET_USER_OKEX( state, user) {
      const { currency, wallet, orders,  blocked, down, localSymbols } = user
      state.okex.currency = currency
      state.okex.wallet = wallet
      state.okex.blocked = blocked
      state.okex.down = down
      state.okex.localSymbols = localSymbols
      state.okex.orders = setOrders(orders, state.okex.orders, state.okex.wallet, state.okex.localSymbols, state.gain)
      state.okex.openOperations = setOperations(orders, state.okex.openOperations)
      state.okex.deficit = setDeficit(orders)
      state.okex.deficitBrl = setDeficit(orders, 'brl')
      state.okex.riskRate = setRiskRate(orders, currency, 0)
      state.okex.riskRateBrl = setRiskRate(orders, currency, 0, 'brl')
      state.okex.capitalAtRisk = setRiskRateUsd(orders)
      state.okex.capitalAtRiskBrl = setRiskRateUsd(orders, 'brl')
      state.okex.valueInPurchases = setValueInPurchases(orders)
      state.okex.valueInPurchasesBrl = setValueInPurchases(orders, 'brl')
      state.okex.walletList = setValueWallet(state.okex.walletList, wallet, orders)
    },

    SET_USER_DEFAULT( state, user) {
      const { currency, wallet, orders,  blocked, down, localSymbols, exchange } = user
      state[exchange].currency = currency
      state[exchange].wallet = wallet
      state[exchange].blocked = blocked
      state[exchange].down = down
      state[exchange].localSymbols = localSymbols
      state[exchange].orders = setOrders(orders, state[exchange].orders, state[exchange].wallet, state[exchange].localSymbols, state.gain)
      state[exchange].openOperations = setOperations(orders, state[exchange].openOperations)
      state[exchange].deficit = setDeficit(orders)
      state[exchange].deficitBrl = setDeficit(orders, 'brl')
      state[exchange].riskRate = setRiskRate(orders, currency, 0)
      state[exchange].riskRateBrl = setRiskRate(orders, currency, 0, 'brl')
      state[exchange].capitalAtRisk = setRiskRateUsd(orders)
      state[exchange].capitalAtRiskBrl = setRiskRateUsd(orders, 'brl')
      state[exchange].valueInPurchases = setValueInPurchases(orders)
      state[exchange].valueInPurchasesBrl = setValueInPurchases(orders, 'brl')
      state[exchange].walletList = setValueWallet(state[exchange].walletList, wallet, orders)
    },

    SET_SHOW_BALANCE( state, show ) {
      state.showBalance = show
    },

    SET_TOKEN( state, token ) {
      state.token = token
      sessionStorage.setItem('token', token)
    },

    SET_LANG( state, lang ) {
      state.valueLang = lang
      localStorage.setItem('valueLang', lang)
    },

    SET_THEME( state, theme ) {
      state.theme = theme
      localStorage.setItem('theme', theme)
    }
  },
  getters: {
    auth( state ) {
        return state.auth;
    },

    showBalance( state ) {
        return state.showBalance;
    },

    token( state ) {
      return state.token;
    },

    lang( state ) {
      return state.valueLang;
    },

    theme( state ) {
      return state.theme;
    },

    currency( state ) {
      return state.currency;
    },

    openOperations( state ) {
      return state[state.exchange].openOperations
    },

    exchangeOperations: ( state ) => (exchange) => {
      return state[exchange.toLowerCase()].openOperations
    },


    operationsByOrderId: ( state )  => (orderId) => {
      return state[state.exchange].openOperations.filter(o => o.orderId === orderId).sort((a, b) => b.id - a.id)
    },

    orders ( state ) {
      return state[state.exchange].orders.filter(o => o.symbol !== "")
    },

    huobiHasOrders ( state ) {
      return state['huobi'].orders.length > 0
    },

    ordersAll ( state ) {
      return [
        ...state['binance'].orders.filter(o => o.symbol !== ""),
        ...state['ftx'].orders.filter(o => o.symbol !== ""),
        ...state['huobi'].orders.filter(o => o.symbol !== ""),
        ...state['kucoin'].orders.filter(o => o.symbol !== ""),
        ...state['okex'].orders.filter(o => o.symbol !== ""),
        ...state['coinex'].orders.filter(o => o.symbol !== "")
      ]
    },

    capitalAtRisk( state, getters ) {
      if (state.exchange === 'binance' && state[state.exchange].current.default === 'brl')
        return getters.capitalAtRiskBrl

      return state[state.exchange].capitalAtRisk
    },

    capitalAtRiskBrl( state ) {
      return state[state.exchange].capitalAtRiskBrl
    },

    riskRateExt: (state) => (value) => {
      return setRiskRate(state[state.exchange].orders, state[state.exchange].currency, value)
    },

    riskRateExtBrl: (state) => (value) => {
      return setRiskRate(state[state.exchange].orders, state[state.exchange].currency, value, 'brl')
    },

    gainByOrderId: (state) => (orderId) => {
      const row = state.gain.find(g => g.orderId === orderId)
      return (row === undefined) ? 0 : row.gain
    },

    gainByOrderIdInfo: (state) => (orderId) => {
      const row = state.gain.find(g => g.orderId === orderId)
      return (row === undefined) ? 0 : row
    },

    findByOrderId: (state) => (orderId) => {
      const row = state[state.exchange].orders.find(o => o.id === orderId)
      return (row === undefined) ? {} : row
    },

    gainByOrderGroupAndExchange: (state) => (group, exchange) => {
      return state.gain.filter(g => g.symbolPar.toUpperCase() === group.toUpperCase() && g.exchange.toUpperCase() === exchange.toUpperCase())
    },

    holdBySymbol: (state) => (symbol, currency) => {
      let row = { amount: 0 }
      if (currency.toUpperCase() !== "BRL") {
        row = state[state.exchange].symbolsOnHold.find(g => g.symbol.toUpperCase() === symbol.toUpperCase())
      } else {
        row = state[state.exchange].symbolsOnHoldBrl.find(g => g.symbol.toUpperCase() === symbol.toUpperCase())
      }

      return (row === undefined) ? 0 : row.amount
    },

    synchronize( state ) {
      return state.synchronize
    },

    valueInPurchases( state, getters ) {
      if (state.exchange === 'binance' && state[state.exchange].current.default === 'brl')
        return getters.valueInPurchasesBrl

      return state[state.exchange].valueInPurchases
    },

    valueInPurchasesBrl( state ) {
      return state[state.exchange].valueInPurchasesBrl
    },

    ordersSelected( state ) {
      return state[state.exchange].orders.filter(o => o.show).filter(o => o.selected === false).length === 0
    },

    usdCurrency( state ) {
      return state.usdCurrency
    },

    showAdmin( state ) {
      return state.showAdmin
    },

    showCompany( state ) {
      return state.showCompany
    },

    online( state ) {
      return state.online
    },

    ftxUsd( state ) {
      return state.ftx.currency.usd
    },

    kucoinUsdt( state ) {
      return state.kucoin.currency.usdt
    },

    coinexUsdt( state ) {
      return state.coinex.currency.usdt
    },

    okexUsdt( state ) {
      return state.okex.currency.usdt
    },

    huobiHusd( state ) {
      return state.huobi.currency.husd
    },

    huobiUsdt( state ) {
      return state.huobi.currency.usdt
    },

    binanceCurrency( state ) {
      return state.binance.currency
    },

    onlyView( state ) {
      try {
        const token = sessionStorage.getItem('token')
        const item = token.split(".")
        const body = atob(item[1])
        const { view } = JSON.parse(body)

        state.onlyView = Boolean(view)
      } catch (e) {
        console.info(e.message)
      }
      return state.onlyView
    },

    exchange ( state ) {
      return state.exchange
    },

    current ( state ) {
      return state[state.exchange].current[state[state.exchange].current.default]
    },

    currentDefault ( state ) {
      return state[state.exchange].current.default
    },

    symbolsOnHold ( state, getters, rootState ) {
      if (state.exchange === 'binance' && state[state.exchange].current.default === 'brl')
        return getters.symbolsOnHoldBrl

      const symbols = rootState.user[state.exchange].orders.map(o => ({ group: o.group, currency: o.currency, value: o.price  }))
      const allow = state[state.exchange].allowPrice

      return state[state.exchange].symbolsOnHold.map(o => {
        let price = 0
        const symbol = symbols.find(s => s.group.toUpperCase() === o.symbol.toUpperCase() &&  [allow.a.toUpperCase(), allow.b.toUpperCase()].includes(s.currency.toUpperCase()))

        if (symbol !== undefined) price = symbol.value

        o.invested = parseFloat(o.invested.toFixed(2))
        o.currentValue = parseFloat((price * o.amount).toFixed(2))

        if (price > 0) o.situation = parseFloat((((o.currentValue / o.invested) * 100) - 100).toFixed(2))
        if (!o.hasOwnProperty("request")) o.request = false

        return o
      })
    },

    symbolsOnHoldBrl ( state, getters, rootState ) {
      const symbols = rootState.user[state.exchange].orders.map(o => ({ group: o.group, currency: o.currency, value: o.price  }))

      return state[state.exchange].symbolsOnHoldBrl.map(o => {
        let price = 0

        const symbol = symbols.find(s => s.group.toUpperCase() === o.symbol.toUpperCase() &&  ['BRL'].includes(s.currency.toUpperCase()))

        if (symbol !== undefined) price = symbol.value

        o.invested = parseFloat(o.invested.toFixed(2))
        o.currentValue = parseFloat((price * o.amount).toFixed(2))

        if (price > 0) o.situation = parseFloat((((o.currentValue / o.invested) * 100) - 100).toFixed(2))
        if (!o.hasOwnProperty("request")) o.request = false

        return o
      })
    },

    sumProfitBrl ( state ) {
      return state[state.exchange].sumProfitBrl
    },

    sumProfit ( state, getters ) {
      if (state.exchange === 'binance' && state[state.exchange].current.default === 'brl')
        return getters.sumProfitBrl

      return state[state.exchange].sumProfit
    },

    // .reduce((a, b)=> a + b, 0)
    sumOfConfirmedAmountOnHold ( state, getters ) {
      if (state.exchange === 'binance' && state[state.exchange].current.default === 'brl')
        return 0

      if (state.exchange === 'binance')
        return state[state.exchange].sumOfConfirmedAmountOnHold

      const value = state.gain.filter(g => g.exchange.toUpperCase() === state.exchange.toUpperCase())
        .map(m => m.holdAccomplished)
        .reduce((a, b)=> a + b, 0)

      return parseFloat(value.toFixed(2))
    },

    deficit( state, getters ) {
      if (state.exchange === 'binance' && state[state.exchange].current.default === 'brl')
        return getters.deficitBrl

      return state[state.exchange].deficit
    },

    deficitBrl( state ) {
      return state[state.exchange].deficitBrl
    },

    riskRate( state, getters ) {
      if (state.exchange === 'binance' && state[state.exchange].current.default === 'brl')
        return getters.riskRateBrl

      return state[state.exchange].riskRate
    },

    riskRateBrl( state ) {
      return state[state.exchange].riskRateBrl
    },

    monthlyGrossProfit( state, getters ) {
      if (state.exchange === 'binance' && state[state.exchange].current.default === 'brl')
        return getters.monthlyGrossProfitBrl

      return state[state.exchange].monthlyGrossProfit
    },

    monthlyGrossProfitBrl( state ) {
      return state[state.exchange].monthlyGrossProfitBrl
    },


    capitalAtRisk( state, getters ) {
      if (state.exchange === 'binance' && state[state.exchange].current.default === 'brl')
        return getters.capitalAtRiskBrl

      return state[state.exchange].capitalAtRisk
    },

    capitalAtRiskBrl( state ) {
      return state[state.exchange].capitalAtRiskBrl
    },

    days( state, getters ) {
      if (state.exchange === 'binance' && state[state.exchange].current.default === 'brl')
        return getters.daysBrl

      return state[state.exchange].days
    },

    daysBrl( state ) {
      return state[state.exchange].daysBrl
    },

    months( state, getters ) {
      if (state.exchange === 'binance' && state[state.exchange].current.default === 'brl')
        return getters.monthsBrl

      return state[state.exchange].months
    },

    walletList( state ) {
      return state[state.exchange].walletList
    },

    monthsBrl( state ) {
      return state[state.exchange].monthsBrl
    },

    blocked (state) {
      return state[state.exchange].blocked
    },

    canChangeExchange (state) {
      return state.canChangeExchange
    },

    changeBroker (state) {
      return state.changeBroker
    },

    freeCapitalOn( state, getters ) {
      if (state.exchange === 'binance' && state[state.exchange].current.default === 'brl') {
        return parseFloat(((state[state.exchange].currency.brl + state[state.exchange].valueInPurchasesBrl) - state[state.exchange].capitalAtRiskBrl).toFixed(2))
      }

      let valueUsd = 0
      for (let key in state[state.exchange].currency) {
        if (key.toUpperCase() !== 'BRL')
          valueUsd += state[state.exchange].currency[key]
      }

      return parseFloat(((valueUsd + state[state.exchange].valueInPurchases) - state[state.exchange].capitalAtRisk).toFixed(2))
    },
  }
};

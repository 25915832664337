export default {
    data: () => {
      return {
        mixErrors: [],
      }
    },
  methods: {
    valueInIf(value) {
      let newValue =  (value || 0).toFixed(2)
      if (this.show) return newValue

      let text = '';
      for (let index = 0; index < newValue.length; index++) text += '*';
      return text;
    },
    verifyErrors(data) {
      if(data.hasOwnProperty("errors") && data.errors.length) {
        const errors = data.errors.map(e => e.message)
        this.setErrors(errors)
        return data.errors.length
      }
      return false
    },

    // remove ponto
    removeDot(value) {
      return value.replace(/\./g, '')
    },

    setErrors(errors) {
      this.mixErrors = errors
    },

    getErrors() {
      return this.mixErrors;
    },

    resetErrors() {
      this.mixErrors = []
    },

    logout(event) {
      sessionStorage.removeItem('token')
      location.href = '/login';
      event.preventDefault()
    },

    initXlsx() {
      const hasXlsxName = document.getElementById("xlsxName")
      if(hasXlsxName === null) {
        let xlsxScript = document.createElement('script')
        xlsxScript.id="xlsxName"
        xlsxScript.setAttribute('src', 'https://unpkg.com/xlsx@0.15.1/dist/xlsx.full.min.js')
        document.head.appendChild(xlsxScript)
      }
    },

    companyVerifyUser() {
      if (!this.user) this.$router.push({ name: 'CompanyUsers' })
    }
  }
};

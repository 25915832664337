import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';

// pages
const Dashboard = () => import('src/pages/Dashboard/index.vue');

const AccountStatus = () => import('src/pages/Overview/AccountStatus/index.vue');
const Reports = () => import('src/pages/Overview/Reports/index.vue');

const Errors = () => import('src/pages/Orders/Errors/index.vue');
const OrdersAll = () => import('src/pages/Orders/All/index.vue');
const OrdersNew = () => import('src/pages/Orders/New/index.vue');
const OrdersEdit = () => import('src/pages/Orders/Edit/index.vue');

const OrdersRemoved = () => import('src/pages/Orders/Removed/index.vue');
const OperationsOpen = () => import('src/pages/Orders/Operations/Open/index.vue');
const OperationsClosed = () => import('src/pages/Orders/Operations/Closed/index.vue');

const CurrentSituation = () => import('src/pages/Market/CurrentSituation/index.vue');
const Ranking = () => import('src/pages/Market/Ranking/index.vue');

const Financial = () => import('src/pages/Financial/index.vue');

const ReferralProgram = () => import('src/pages/ReferralProgram/index.vue');

const FrequentlyAskedQuestions = () => import('src/pages/Support/FrequentlyAskedQuestions/index.vue');
const ContactUs = () => import('src/pages/Support/ContactUs/index.vue');

const Settings = () => import('src/pages/Settings/index.vue');
const Wallet = () => import('src/pages/Wallet/index.vue');

const Login = () => import('src/pages/Pages/Login.vue');
const Register = () => import('src/pages/Pages/Register.vue');
const ResetPassword = () => import('src/pages/Pages/ResetPassword.vue');
const Terms = () => import('src/pages/Pages/Terms.vue');

const Users = () => import('src/pages/Users/index.vue');
const UserIndex = () => import('src/pages/Users/Pages/index.vue');
const Accounting = () => import('@/pages/Users/Pages/accounting.vue');

const Company = () => import('src/pages/Company/index.vue');
const CompanyDefault = () => import('src/pages/Company/Pages/default.vue');

const CompanyUsers = () => import('src/pages/Company/Pages/users/index.vue');
const CompanyNewUser = () => import('src/pages/Company/Pages/users/new.vue');
const CompanyEditUser = () => import('src/pages/Company/Pages/users/edit.vue');

const CompanyOrdersUser = () => import('src/pages/Company/Pages/users/orders/index.vue');
const CompanyNewOrdersUser = () => import('src/pages/Company/Pages/users/orders/new.vue');
const CompanyEditOrdersUser = () => import('src/pages/Company/Pages/users/orders/edit.vue');
const CompanyOperationsOpenUser = () => import('src/pages/Company/Pages/users/orders/operations/open/index.vue');
const CompanyOperationsClosedUser = () => import('src/pages/Company/Pages/users/orders/operations/closed/index.vue');

const CompanyErrorsUser = () => import('src/pages/Company/Pages/users/errors/index.vue');

const CompanyOverviewUser = () => import('src/pages/Company/Pages/users/overview/index.vue');
const CompanyFinancial = () => import('src/pages/Company/Pages/financial/index.vue');

const CompanyView = () => import('src/pages/Pages/CompanyView.vue');

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/compnay/view',
    name: 'CompanyView',
    component: CompanyView
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        meta: { auth: true },
        components: { default: Dashboard }
      },
      {
        path: 'overview/account-status',
        name: 'AccountStatus',
        meta: { auth: true },
        components: { default: AccountStatus }
      },
      {
        path: 'overview/reports',
        name: 'Reports',
        meta: { auth: true },
        components: { default: Reports }
      },
      {
        path: 'errors',
        name: 'Errors',
        meta: { auth: true },
        components: { default: Errors }
      },
      {
        path: 'orders/all',
        name: 'Orders',
        meta: { auth: true },
        components: { default: OrdersAll }
      },
      {
        path: 'orders/new',
        name: 'OrdersNew',
        meta: { auth: true },
        components: { default: OrdersNew }
      },
      {
        path: 'orders/mass-editing',
        name: 'OrdersEdit',
        meta: { auth: true },
        components: { default: OrdersEdit }
      },
      {
        path: 'orders/removed',
        name: 'OrdersRemoved',
        meta: { auth: true },
        components: { default: OrdersRemoved }
      },
      {
        path: 'orders/operations/open',
        name: 'OperationsOpen',
        meta: { auth: true },
        components: { default: OperationsOpen }
      },
      {
        path: 'orders/operations/closed',
        name: 'OperationsClosed',
        meta: { auth: true },
        components: { default: OperationsClosed }
      },
      {
        path: 'market/current-situation',
        name: 'CurrentSituation',
        meta: { auth: true },
        components: { default: CurrentSituation }
      },
      {
        path: 'market/ranking',
        name: 'Ranking',
        meta: { auth: true },
        components: { default: Ranking }
      },
      {
        path: 'financial',
        name: 'Financial',
        meta: { auth: true },
        components: { default: Financial }
      },
      {
        path: 'referral-program',
        name: 'ReferralProgram',
        meta: { auth: true },
        components: { default: ReferralProgram }
      },
      {
        path: 'support/frequently-asked-questions',
        name: 'FrequentlyAskedQuestions',
        meta: { auth: true },
        components: { default: FrequentlyAskedQuestions }
      },
      {
        path: 'support/contact-us',
        name: 'ContactUs',
        meta: { auth: true },
        components: { default: ContactUs }
      },
      {
        path: 'settings',
        name: 'Settings',
        meta: { auth: true },
        components: { default: Settings }
      },
      {
        path: 'wallet',
        name: 'Wallet',
        meta: { auth: true },
        components: { default: Wallet }
      },
      {
        path: 'users',
        name: 'Users',
        meta: { auth: true },
        components: { default: Users },
        children: [,
          {
            path: '',
            name: 'UserIndex',
            meta: { auth: true },
            components: { default: UserIndex }
          },
          {
            path: 'accounting',
            name: 'Accounting',
            meta: { auth: true },
            components: { default: Accounting }
          }
        ]
      }
    ]
  },
  { path: '*', redirect: '/login', }
];

export default routes;

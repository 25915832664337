import * as chartConfigs from '@/components/Charts/config';
import config from '@/config';

export const barMonthlyPerformance = () => {
  return {
    chartData: {
      labels: [],
      datasets: [
        {
          label: 'Q1 USD',
          fill: true,
          backgroundColor: config.colors.default,
          hoverBackgroundColor: config.colors.default,
          borderColor: config.colors.default,
          borderWidth: 1,
          borderDash: [],
          borderDashOffset: 0.0,
          data: []
        },
        {
          label: 'Q2 USD',
          fill: true,
          backgroundColor: config.colors.primary,
          hoverBackgroundColor: config.colors.primary,
          borderColor: config.colors.primary,
          borderWidth: 1,
          borderDash: [],
          borderDashOffset: 0.0,
          data: []
        }
      ]
    },
    extraOptions: chartConfigs.barChartOptionsGradient
  }
}

export const barMonthlyPerformanceBrl = () => {
  return {
    chartData: {
      labels: [],
      datasets: [
        {
          label: 'Q1 USD',
          fill: true,
          backgroundColor: config.colors.defaultBrl,
          hoverBackgroundColor: config.colors.defaultBrl,
          borderColor: config.colors.defaultBrl,
          borderWidth: 1,
          borderDash: [],
          borderDashOffset: 0.0,
          data: []
        },
        {
          label: 'Q2 USD',
          fill: true,
          backgroundColor: config.colors.primaryBrl,
          hoverBackgroundColor: config.colors.primaryBrl,
          borderColor: config.colors.primaryBrl,
          borderWidth: 1,
          borderDash: [],
          borderDashOffset: 0.0,
          data: []
        }
      ]
    },
    extraOptions: chartConfigs.barChartOptionsGradient
  }
}

export const lineDaysPerformance = () => {
  return {
    chartData: {
      labels: [],
      datasets: [
        {
          legend: { position: 'top', align: 'start', display: true },
          backgroundColor: '#00796b',
          borderColor: '#00796b',
          borderWidth: 1,
          borderCapStyle: 'round',
          fill: false,
          label: 'USD',
          data: []
        }
      ]
    },
    extraOptions: chartConfigs.lineChartOptionsBlue

  }
}

export const lineDaysPerformanceBrl = () => {
  return {
    chartData: {
      labels: [],
      datasets: [
        {
          legend: { position: 'top', align: 'start', display: true },
          backgroundColor: '#009c3b',
          borderColor: '#009c3b',
          borderWidth: 1,
          borderCapStyle: 'round',
          fill: false,
          label: 'USD',
          data: []
        }
      ]
    },
    extraOptions: chartConfigs.lineChartOptionsBlue

  }
}

export const piePosition = () => {
  return {
    chartData: {
      labels: [],
      datasets: [
        {
          backgroundColor: ['#4B0082', '#8B008B', '#F08080', '#E9967A', '#FAF0E6'],
          data: [],
        }
      ]
    },
    extraOptions: chartConfigs.lineChartOptionsBlue

  }
}
